import {api, safeCall } from '../api';

export const findById = async (roundId) => {
    return safeCall(api.get(`/admin/round/${roundId}`));
};

export const findAllByGameId = async (gameId) => {
    return safeCall(api.get(`/admin/round/game-rounds/${gameId}`));
};

export const createNewRound = async (gameShortId) => {
    return safeCall(api.post(`/admin/round/start-round`, {
        gameId: gameShortId
    }));
};

export const closeBet = async (roundId) => {
    return safeCall(api.patch(`/admin/round/${roundId}/close-bet`));
};

export const closeRound = async (roundId, winningNumber) => {
    return safeCall(api.patch(`/admin/round/${roundId}/close-round`, {
        winningNumber
    }));
};
