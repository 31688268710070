import * as AuthService from './auth';
import * as GameService from './game';
import * as BetService from './bet';
import * as UserService from './user';

// Admin
import * as AdminRoundService from './admin/round';
import * as AdminUserService from './admin/user';
import * as AdminGameService from './admin/game';
import * as AdminAccountService from './admin/account';
import * as AdminBetService from './admin/bet';

const Admin = {
    RoundService: AdminRoundService,
    UserService: AdminUserService,
    GameService: AdminGameService,
    AccountService: AdminAccountService,
    BetService: AdminBetService,
};

export {
    AuthService,
    GameService,
    BetService,
    UserService,
    Admin
}