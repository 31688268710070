import React, {useMemo} from "react";
import {Button, Popconfirm, Space, Table} from "antd";
import { currencyFormat } from '../../../common/currency';

import "./BetTable.style.css";

const emptyFn = () => {}
const getColumns = ({
                        goToUserDetail = emptyFn,
                        goToRoundDetail = emptyFn,
                        goToGameDetail = emptyFn,
                        cancelBet = emptyFn
                    }) => [
    {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (_, { user }) => (
            <a onClick={() => goToUserDetail(user.username)}>{user.firstName} {user.lastName}</a>
        ),
        responsive: ['lg']

    },
    {
        title: 'Game',
        dataIndex: 'gameId',
        key: 'gameId',
        render: (_, { game }) => (
            <a onClick={() => goToGameDetail(game.shortId)}>{game.shortId}</a>
        ),
        responsive: ['lg']
    },
    {
        title: 'Round NO',
        dataIndex: 'roundNo',
        key: 'roundNo',
        render: (_, { game, round }) => (
            <a onClick={() => goToRoundDetail(game.shortId, round._id)}>Round {round.roundNumber}</a>
        ),
        responsive: ['lg']
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Number',
        dataIndex: 'selectedNumber',
        key: 'selectedNumber',
    },
    {
        title: 'Bet Amount',
        dataIndex: 'amount',
        key: 'amount',
        responsive: ['md'],
        render: (_, { amount}) => currencyFormat(amount)
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        responsive: ['lg'],
    },
    {
        title: 'Is Win',
        dataIndex: 'isWon',
        key: 'isWon',
        responsive: ['md'],
        render: (_, { isWon}) => isWon ? 'YES' : 'NO'

    },
    {
        title: 'Multiplier',
        dataIndex: 'multiplier',
        key: 'multiplier',
        responsive: ['md'],
        render: (_, { multiplier}) => multiplier ? `x${multiplier}` : '-'

    },
    {
        title: 'Win Amount',
        dataIndex: 'winAmount',
        key: 'winAmount',
        responsive: ['md'],
        render: (_, { winAmount}) => currencyFormat(winAmount)

    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Popconfirm
                    placement="bottom"
                    title={"Are you sure?"}
                    description={"Bet will be cancelled!"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => cancelBet(record._id)}
                >
                    <Button type="primary" danger
                            disabled={record.status !== "ACTIVE" && record.round.status === "ACTIVE"}
                            >Cancel</Button>
                </Popconfirm>
            </Space>
        ),
    },
];

const BetTable = ({ loading, data,
                      goToUserDetail = emptyFn, goToRoundDetail = emptyFn,
                      goToGameDetail = emptyFn, cancelBet = emptyFn }) => {
    const columns = useMemo(() => {
        return getColumns({
            goToUserDetail,
            goToRoundDetail,
            goToGameDetail,
            cancelBet
        })
    }, [goToUserDetail]);
    return <Table
        expandable
        columns={columns}
        dataSource={data}
        loading={loading}
        tableLayout="fixed"
        rowClassName={({status}) => {
            if(status === "COMPLETED") {
                return "active-table-row"
            }
            if(status === "CANCELLED") {
                return "closed-table-row"
            }

            return "";
        }}
    />
}

export default BetTable;