import React, {useMemo} from 'react';
import {useAuthCtx} from "../../../contexts/AuthContexts";
import {Avatar, Button, Flex, Popconfirm} from "antd";
import {useNavigate} from 'react-router-dom';
import {ToastContainer} from "react-toastify";

const AdminLayoutPage = ({ children}) => {
    const { loggedInUser, logout } = useAuthCtx()
    const navigate = useNavigate();

    const userName = useMemo(() => {
        return loggedInUser.firstName + ' ' + loggedInUser.lastName;
    }, [loggedInUser]);

    const userAvatar = useMemo(() => {
        return loggedInUser.firstName[0].toUpperCase()  + loggedInUser.lastName[0].toUpperCase();
    }, [loggedInUser]);

    return (
        <div>
            <div className="admin-nav-menu">
                <Flex gap="large" justify="space-around" align="center" className="admin-nav-logo">
                    <Button onClick={() => navigate(-1)}>{"<"}</Button>
                    <Avatar onClick={() => navigate("/admin/home")} size={40}>{userAvatar}</Avatar>
                    <span> Hoş Geldin, {userName}</span>
                </Flex>
                <Flex gap="large" justify="flex-end" align="center" className="admin-nav-name">
                    <Popconfirm
                        placement="bottom"
                        title={"Are you sure?"}
                        description={"You'll be logged out!"}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={logout}
                    >
                        <Button type="primary" danger>Logout</Button>
                    </Popconfirm>
                </Flex>

            </div>
            <div className="admin-container">
                {children}
            </div>
            <ToastContainer
                className={"toast-message"}
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>

    );
};

export default AdminLayoutPage;