import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Flex, Input, Popconfirm, Result, Row, Space, Spin, Statistic} from "antd";
import {
    useParams, useSearchParams
} from "react-router-dom";
import CountUp from "react-countup";
import {Backend} from "../../../service";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import { currencyFormat } from '../../../common/currency';

import BetTable from "../../../components/Tables/BetTable/BetTable.component";

const AdminBetList = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const roundId = searchParams.get('roundId')
    const username = searchParams.get('username')

    const [fetchingBets, setFetchingBets] = useState(true);
    const [bets, setBets] = useState(null)

    const [cancellingBet, setCancellingBet] = useState(false);

    const loading = useMemo(() => {
        return fetchingBets && cancellingBet;
    }, [ fetchingBets, cancellingBet]);

    const goToGame = useCallback((gameShortId) => {
        navigate('/admin/game/' + gameShortId);
    }, [navigate]);

    const goToRound = useCallback((gameShortId, roundId) => {
        navigate(`/admin/game/${gameShortId}/round/${roundId}`);
    }, [navigate]);

    const goToUser = useCallback((userId) => {
        navigate(`/admin/user/${userId}`);
    }, [navigate]);

    const cancelBet = useCallback((betId) => {
        setCancellingBet(true)
        Backend.Admin.BetService.cancelBet({
            betId,
        })
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message)
                    return
                }
                updateBets();
            })
            .finally(() => {
                setCancellingBet(false);
            })
    }, []);

    const updateBets = useCallback(() => {
        setFetchingBets(true)
        Backend.Admin.BetService.searchBets({
            roundId, username,
        }).then((res) => {
            if(!res.success) {
                setBets([]);
                toast.error(res.message)
                return
            }
            setBets(res.data);
        })
        .finally(() => {
            setFetchingBets(false);
        })
    }, [roundId, username]);

    useEffect(() => {
        updateBets();
    }, []);

    return (
        <div className="page-container" style={{alignItems: 'flex-start'}}>
            {
                loading && <Spin fullscreen tip="Yükleniyor..."/>
            }
            <div className="admin-detail-container">
                <div className="admin-detail-header">
                    <span>Bets</span>
                </div>
                <div className="admin-detail-block">
                    <BetTable
                        data={bets}
                        loading={fetchingBets}
                        goToUserDetail={goToUser}
                        goToRoundDetail={goToRound}
                        goToGameDetail={goToGame}
                        cancelBet={cancelBet}
                    />
                </div>

            </div>
        </div>
    );
}

export default AdminBetList;