import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Flex, Input, Popconfirm, Result, Row, Space, Spin, Statistic} from "antd";
import {
    useParams
} from "react-router-dom";
import CountUp from "react-countup";
import {Backend} from "../../../service";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import { currencyFormat } from '../../../common/currency';

import UserBetSummaryTable from "../../../components/Tables/UserBetSummaryTable/UserBetSummaryTable.component";

const counterFormatter = (value) => <CountUp end={value} separator="," />;
const currencyFormatter = (value) => currencyFormat(value);

const AdminRoundDetail = () => {
    const navigate = useNavigate();

    let { gameShortId, roundId } = useParams();

    const [fetchingRound, setFetchingRound] = useState(true);
    const [roundDetail, setRoundDetail] = useState(null);

    const [closingBets, setClosingBets] = useState(false);
    const [closingRound, setClosingRound] = useState(false);
    const [winningNumber, setWinningNumber] = useState(0);
    const [cancellingBets, setCancellingBets] = useState(false);

    const loading = useMemo(() => {
        return fetchingRound || closingBets || closingRound || cancellingBets
    }, [ closingBets, closingRound, fetchingRound, cancellingBets]);

    const goToGame = useCallback(() => {
        navigate('/admin/game/' + gameShortId);
    }, [navigate, gameShortId]);

    const goToUser = useCallback((username) => {
        navigate('/admin/user/' + username);
    }, [navigate]);

    const goToUserBetList = useCallback((username) => {
        navigate(`/admin/bets?username=${username}&roundId=${roundId}`);
    }, [navigate, roundId]);

    const updateRoundDetail = useCallback(() => {
        setFetchingRound(true)
        Backend.Admin.RoundService.findById(roundId).then((res) => {
            if(!res.success) {
                setRoundDetail(null);
                toast.error(res.message)
                return
            }
            setRoundDetail(res.data);
        })
        .finally(() => {
            setFetchingRound(false);
        })
    }, [roundId]);

    const cancelAllUserBet = useCallback((userId) => {
        setCancellingBets(true)
        Backend.Admin.BetService.cancelUserAllBet({roundId, userId}).then((res) => {
            if(!res.success) {
                setRoundDetail(null);
                toast.error(res.message)
                return
            }
            updateRoundDetail();
        })
        .finally(() => {
            setCancellingBets(false);
        })
    }, [roundId]);

    const closeBets = useCallback(() => {
        if(!roundDetail) {
            toast.error("Round detail does not exist!")
            return
        }
        if(roundDetail.status !== 'ACTIVE') {
            toast.warn("Bets were already closed!")
            return
        }
        setClosingBets(true)
        Backend.Admin.RoundService.closeBet(roundDetail._id).then((res) => {
            if(!res.success) {
                toast.error(res.message)
                return
            }
            toast('Bets closed')
            updateRoundDetail()
        })
            .finally(() => {
                setClosingBets(false);
            })
    }, [roundDetail]);

    const closeRound = useCallback(() => {
        if(!roundDetail) {
            toast.error("Round detail does not exist!")
            return
        }
        if(roundDetail.status === 'CLOSED') {
            toast.warn("Round was already closed!")
            return
        }
        setClosingRound(true)
        Backend.Admin.RoundService.closeRound(roundDetail._id, winningNumber)
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message)
                    return
                }
                toast('Round closed')
                goToGame()
            })
            .finally(() => {
                setClosingRound(false);
            })
    }, [roundDetail, winningNumber, goToGame]);

    useEffect(() => {
        updateRoundDetail();
    }, [])

    if(!roundDetail && fetchingRound) {
        return <div className="page-container">
            <Spin fullscreen tip="Yükleniyor..."/>
        </div>
    }

    if(!roundDetail) {
        return <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", paddingTop: "70px" }}>
            <div style={{ backgroundColor: "white", width: "40vw", height: "60vh" }}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the round you visited does not exist."
                    extra={<Button type="primary" onClick={goToGame}>Back TO Game</Button>}
                />
            </div>
        </div>
    }

    return (
        <div className="page-container" style={{alignItems: 'flex-start'}}>
            {
                loading && <Spin fullscreen tip="Yükleniyor..."/>
            }
            <div className="admin-detail-container">
                <div className="admin-detail-header">
                    <span>Round No: {roundDetail.roundNumber}</span>
                    <span>Round Status: {roundDetail.status}</span>
                </div>
                {
                    roundDetail.status === 'ACTIVE' &&
                    <div className="admin-detail-block admin-round-actions">
                        <Button block onClick={closeBets} type="primary">Close Bet</Button>
                    </div>
                }
                {
                    roundDetail.status === 'BET_CLOSED' &&
                    <div className="admin-detail-block admin-round-actions">
                        <Input
                            type="number"
                            value={winningNumber.toString()}
                            min={0}
                            max={36}
                            onChange={(e) => {
                                let val = Number(e.target.value);
                                if(val<0) {
                                    val=0;
                                }
                                if(val >36) {
                                    val=36
                                }
                                setWinningNumber(val)
                            }}
                        />
                        <Popconfirm
                            placement="bottom"
                            title={"Are you sure?"}
                            description={`Round will be closed. Winning number will be ${winningNumber}.`}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={closeRound}
                        >
                            <Button type="primary" danger>Close Round</Button>
                        </Popconfirm>
                    </div>
                }
                <div className="admin-detail-block">
                    <div className="admin-detail-statistic">
                        <Card bordered={false} size="default" className="static-card">
                            <Statistic
                                title="Bet Count"
                                value={roundDetail.betCount}
                                valueStyle={{ color: '#3f8600' }}
                            />
                        </Card>
                        <Card bordered={false} className="static-card">
                            <Statistic
                                title="User Count"
                                value={roundDetail.userCount}
                            />
                        </Card>
                        <Card bordered={true} className="static-card">
                            <Statistic
                                title="Active Bet Count"
                                value={roundDetail.summary.activeCount}
                            />
                        </Card>
                        <Card bordered={false} className="static-card">
                            <Statistic
                                title="Total Bet"
                                value={roundDetail.summary.totalBetAmount}
                                formatter={currencyFormatter}
                            />
                        </Card>
                        <Card bordered={false} className="static-card">
                            <Statistic
                                title="Total Win"
                                value={roundDetail.summary.totalWinAmount}
                                formatter={currencyFormatter}
                            />
                        </Card>
                    </div>
                </div>
                <div className="admin-detail-block">
                    <Button onClick={updateRoundDetail} type="primary" block>Refresh</Button>
                </div>
                <div className="admin-detail-block">
                    <UserBetSummaryTable
                        data={roundDetail.userBetSummaries}
                        loading={fetchingRound}
                        goToUserDetail={goToUser}
                        goToUserBetList={goToUserBetList}
                        cancelAllUserBet={cancelAllUserBet}
                    />
                </div>

            </div>
        </div>
    );
}

export default AdminRoundDetail;