import React from 'react';
import {Flex} from "antd";

import "./Loading.style.css"
import RouletteWheel from "../../components/RouletteWheel/RouletteWheel.component";

const LoadingPage = () => {
    return (
        <div className="bg-container">
            <Flex align="center" gap="middle">
                <RouletteWheel/>
            </Flex>
        </div>
    );
};

export default LoadingPage;