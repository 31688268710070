import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Flex, Input, Modal, Space, Spin, Table, Tabs} from "antd";
import {Backend} from "../../service";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import GameTable from "../../components/Tables/GamesTable/GamesTable.component";
import UsersTable from "../../components/Tables/UsersTable/UsersTable.component";
import GameQRCode from "../../components/GameQRCode/GameQRCode.component";

const AdminHomePage = () => {
    const navigate = useNavigate();

    //QR Modal
    const [isQRModalOpen, setIsQRModalOpen] = useState(false);
    const [modalGameId, setModelGameId] = useState('');

    //Game Modal
    const [isGameModelOpen, setIsGameModelOpen] = useState(false);
    const [modalGameName, setModalGameName] = useState('');
    const [creatingGame, setCreatingGame] = useState(false);

    //User Modal
    const [isUserModelOpen, setIsUserModelOpen] = useState(false);
    const [creatingUser, setCreatingUser] = useState(false);
    const [modalUserInfo, setModelUserInfo] = useState({});

    const [games, setGames] = useState([]);
    const [gamesLoading, setGamesLoading] = useState(true);

    const [users, setUsers] = useState([]);
    const [usersLoading, setUsersLoading] = useState(true);

    const loading = useMemo(() => {
        return creatingGame || creatingUser
    }, [creatingGame, creatingUser]);

    const goToGameDetail = useCallback((gameId) => {
        navigate('/admin/game/' + gameId)
    }, []);

    const openQRModal = useCallback((gameId) => {
        setModelGameId(gameId)
        setIsQRModalOpen(true)
    }, []);

    const closeQRModal = useCallback((gameId) => {
        setModelGameId('')
        setIsQRModalOpen(false)
    }, []);

    const goToUserDetail = useCallback((username) => {
        navigate('/admin/user/' + username)
    }, []);


    // Game Modal
    const openGameModal = useCallback(() => {
        setModalGameName('')
        setIsGameModelOpen(true)
    }, []);

    const closeGameModal = useCallback(() => {
        setModalGameName('')
        setIsGameModelOpen(false)
    }, []);

    const createGame = useCallback(() => {
        if(!modalUserInfo) {
            toast.error("User On is empty");
            return
        }
        setCreatingGame(true)
        Backend.Admin.GameService.createGame(modalGameName)
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message);
                    return
                }
                goToGameDetail(res.data.gameId);
            })
            .finally(() => {
                setCreatingGame(true);
            })
    }, [modalUserInfo]);

    // User Modal
    const openUserModal = useCallback(() => {
        setModelUserInfo({})
        setIsUserModelOpen(true)
    }, []);

    const closeUserModal = useCallback(() => {
        setModelUserInfo({})
        setIsUserModelOpen(false)
    }, []);

    const createUser = useCallback(() => {
        if(!modalUserInfo) {
            toast.error("User Info is null");
            return
        }
        const {
            firstName, lastName, username, password
        } = modalUserInfo;
        if(
            !firstName || firstName.length < 3 ||
            !lastName || lastName.length < 3 ||
            !username || username.length < 5 ||
            !password || password.length < 3
        ) {
            toast.error("User Info is invalid");
            return
        }

        setCreatingUser(true)
        Backend.Admin.UserService.createUser(modalUserInfo)
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message);
                    return
                }
                updateUsers();
                closeUserModal();
            })
            .finally(() => {
                setCreatingUser(false);
            })
    }, [modalUserInfo]);


    const updateGames = useCallback(() => {
        setGamesLoading(true);
        Backend.Admin.GameService.findAll().then(res => {
            if(!res.success) {
                toast.error(res.message)
                return
            }
            setGames(res.data)
        }).finally(() => {
            setGamesLoading(false);
        })
    }, []);

    const updateUsers = useCallback(() => {
        setUsersLoading(true);
        Backend.Admin.UserService.findAll().then(res => {
            if(!res.success) {
                toast.error(res.message)
                return
            }
            setUsers(res.data)
        }).finally(() => {
            setUsersLoading(false);
        })
    }, []);



    useEffect(() => {
        updateGames();
        updateUsers();
    }, []);

    const tabItems = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Games',
                children:
                    <div>
                        <div className="admin-detail-block">
                            <Button onClick={updateGames} type="primary" block>Refresh Games</Button>
                        </div>
                        <div className="admin-detail-block admin-table-buttons">
                            <Button onClick={openGameModal}>Create New Game</Button>
                        </div>
                        <GameTable
                            data={games}
                            loading={gamesLoading}
                            goToGameDetail={goToGameDetail}
                            goToGameQr={openQRModal}
                        />
                    </div>,
            },
            {
                key: '2',
                label: 'Users',
                children: <div>
                    <div className="admin-detail-block">
                        <Button onClick={updateUsers} type="primary" block>Refresh Users</Button>
                    </div>
                    <div className="admin-detail-block admin-table-buttons">
                        <Button onClick={openUserModal}>Create New User</Button>
                    </div>
                    <UsersTable data={users} loading={usersLoading} goToUserDetail={goToUserDetail}/>
                </div>
            },
        ]
    }, [games, gamesLoading, goToGameDetail, users, usersLoading, goToUserDetail]);

    return (
        <div className="bg-container">
            {
                loading && <Spin fullscreen tip="Yükleniyor..."/>
            }
            <div className="admin-detail-container">
                <Flex align="center" gap="middle">
                    <Tabs defaultActiveKey="1" items={tabItems} />
                </Flex>
            </div>

            <Modal title="Game QR Code" open={isQRModalOpen} onOk={closeQRModal} onCancel={closeQRModal}>
                <GameQRCode gameId={modalGameId}/>
            </Modal>

            <Modal title="Create New Game" open={isGameModelOpen} onOk={createGame} onCancel={closeGameModal}>
               <Input value={modalGameName} onChange={(e) => setModalGameName(e.target.value)} placeholder="Game Name" />
            </Modal>

            <Modal title="Create New User" open={isUserModelOpen} onOk={createUser} onCancel={closeUserModal}>
                <Input
                    value={modalUserInfo.username || ''}
                    onChange={(e) => setModelUserInfo(u => ({
                        ...u,
                        username: e.target.value
                    }))}
                    placeholder="Username"
                />
                <Input.Password
                    value={modalUserInfo.password || ''}
                    onChange={(e) => setModelUserInfo(u => ({
                        ...u,
                        password: e.target.value
                    }))}
                    placeholder="Password"
                />
                <Input
                    value={modalUserInfo.firstName || ''}
                    onChange={(e) => setModelUserInfo(u => ({
                        ...u,
                        firstName: e.target.value
                    }))}
                    placeholder="First Name"
                />
                <Input
                    value={modalUserInfo.lastName || ''}
                    onChange={(e) => setModelUserInfo(u => ({
                        ...u,
                        lastName: e.target.value
                    }))}
                    placeholder="Last Name"
                />
            </Modal>
        </div>
    );
};

export default AdminHomePage;