import currency from 'currency.js'

export const currencyFormat = (value, symbol = '₺') => {
    if(!value) {
        value = 0;
    }
    return currency(value, {
        symbol: symbol
    }).format({
        symbol: symbol
    });
}