import axios from 'axios';
import { BACKEND_HOST } from '../../constants/api.constants';

export const api = axios.create({
    baseURL: BACKEND_HOST,
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

export async function safeCall(reqPromise) {
    try {
        const response = await reqPromise;
        return response.data;
    } catch (err) {
        const httpResponse = {
            status: err.response ? err.response.status : 0,
            statusText: err.response ? err.response.statusText : 'Something went wrong!',
        };

        if(err.response && err.response.data) {
            return {
                ...err.response.data,
                httpResponse
            }
        }
        if(err.response && [401, 403].includes(err.response.status)) {
            return {
                success: false,
                message: httpResponse.statusText,
                httpResponse,
            }
        }

        console.error(err)
        return {
            success: false,
            message: 'Something went wrong',
            httpResponse
        }
    }
}