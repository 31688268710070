import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Input, Popconfirm, Result, Spin} from "antd";
import {
    useParams
} from "react-router-dom";
import CountUp from "react-countup";
import {Backend} from "../../../service";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import { currencyFormat } from '../../../common/currency';
import TransactionTable from "../../../components/Tables/TransactionsTable/TransactionTable.component";

import "./UserDetail.style.css";

const AdminUserDetail = () => {
    const navigate = useNavigate();

    let { username } = useParams();

    const [depositValue, setDepositValue] = useState(0);
    const [withdrawValue, setWithdrawValue] = useState(0);
    const [sendingRequest, setSendingRequest] = useState(false);

    const [fetchingUserDetail, setFetchingUserDetail] = useState(true);
    const [userDetail, setUserDetail] = useState(null);

    const [fetchingTransactions, setFetchingTransactions] = useState(true);
    const [transactions, setTransactions] = useState([]);

    const loading = useMemo(() => {
        return fetchingUserDetail || fetchingTransactions || sendingRequest
    }, [ fetchingUserDetail, fetchingTransactions, sendingRequest]);

    const goToHome = useCallback(() => {
        navigate('/admin/home');
    }, [navigate]);

    const updateUserDetail = useCallback(() => {
        setFetchingUserDetail(true)
        Backend.Admin.UserService.findByUsername(username)
            .then((res) => {
                if(!res.success) {
                    setUserDetail(null);
                    toast.error(res.message)
                    return
                }
                setUserDetail(res.data);
            })
            .finally(() => {
                setFetchingUserDetail(false);
            })
    }, [username]);

    const updateUserTransactions = useCallback((userDetail) => {
        if(!userDetail) {
            setFetchingTransactions(false);
            setTransactions([]);
            return
        }
        setFetchingTransactions(true);
        Backend.Admin.AccountService.findAllTransactionsByUsername(userDetail.username)
            .then((res) => {
                if(!res.success) {
                    setTransactions([]);
                    toast.error(res.message)
                    return
                }
                setTransactions(res.data);
            })
            .finally(() => {
                setFetchingTransactions(false);
            })
    }, []);

    const handleDeposit = useCallback(() => {
        if(!userDetail) {
            toast.error("User detail does not exist!");
            return
        }
        if(!depositValue || depositValue < 0 || isNaN(Number(depositValue))) {
            toast.error("Invalid deposit value");
            return
        }
        setSendingRequest(true);
        Backend.Admin.AccountService.deposit(userDetail.username, Number(depositValue))
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message);
                    return
                }
                setDepositValue(0);
                setWithdrawValue(0);
                updateUserDetail();
                updateUserTransactions();
            })
            .finally(() => {
                setSendingRequest(false);
            })
    }, [userDetail, depositValue]);

    const handleWithdraw = useCallback(() => {
        if(!userDetail) {
            toast.error("User detail does not exist!");
            return
        }
        if(!withdrawValue || withdrawValue < 0 || isNaN(Number(withdrawValue))) {
            toast.error("Invalid withdraw value");
            return
        }
        setSendingRequest(true);
        Backend.Admin.AccountService.withdraw(userDetail.username, Number(withdrawValue))
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message);
                    return
                }
                setDepositValue(0);
                setWithdrawValue(0);
                updateUserDetail();
                updateUserTransactions();
            })
            .finally(() => {
                setSendingRequest(false);
            })
    }, [userDetail, withdrawValue]);

    useEffect(() => {
        updateUserDetail();
    }, []);

    useEffect(() => {
        updateUserTransactions(userDetail);
    }, [userDetail])

    if(!userDetail && fetchingUserDetail) {
        return <div className="page-container">
            <Spin fullscreen tip="Yükleniyor..."/>
        </div>
    }

    if(!userDetail) {
        return <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", paddingTop: "70px" }}>
            <div style={{ backgroundColor: "white", width: "40vw", height: "60vh" }}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the user you visited does not exist."
                    extra={<Button type="primary" onClick={goToHome}>Back To Home</Button>}
                />
            </div>
        </div>
    }

    return (
        <div className="page-container" style={{alignItems: 'flex-start'}}>
            {
                loading && <Spin fullscreen tip="Yükleniyor..."/>
            }
            <div className="admin-detail-container">
                <div className="admin-detail-header">
                    <span>Name: {userDetail.firstName} {userDetail.lastName}</span>
                    <span>Username: {userDetail.username}</span>
                    <span>Balance: {currencyFormat(userDetail.balance)}</span>
                </div>

                <div className="admin-detail-block admin-user-buttons">
                    <div className="admin-user-button-container">
                        <Input
                            value={depositValue}
                            onChange={(e) => setDepositValue(e.target.value)}
                        />
                        <Popconfirm
                            placement="bottom"
                            title={"Are you sure?"}
                            description={<span>User will deposit {currencyFormat(depositValue)}</span>}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={handleDeposit}
                        >
                            <Button type="primary" success>DEPOSIT</Button>
                        </Popconfirm>
                    </div>
                    <div className="admin-user-button-container">
                        <Input
                            value={withdrawValue}
                            onChange={(e) => setWithdrawValue(e.target.value)}
                        />
                        <Popconfirm
                            placement="bottom"
                            title={"Are you sure?"}
                            description={<span>User will withdraw {currencyFormat(withdrawValue)}</span>}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={handleWithdraw}
                        >
                            <Button type="primary" danger>WITHDRAW</Button>
                        </Popconfirm>
                    </div>
                </div>
                <div className="admin-detail-block">
                    <Button onClick={updateUserDetail} type="primary" block>Refresh</Button>
                </div>
                <div className="admin-detail-block">
                    <TransactionTable
                        loading={fetchingTransactions}
                        data={transactions}
                    />
                </div>
            </div>
        </div>
    );
}

export default AdminUserDetail;