import React, {useMemo} from 'react';
import {QRCode} from "antd";


const GameQRCode = ({ gameId })  => {
    const url = useMemo(() => {
        let port = null;
        if(window.location.port !== '80' || window.location.port !== '') {
            port = ':' + window.location.port;
        }
        const currentHost = `${window.location.protocol}//${window.location.hostname}${port}`;
        return gameId ? `${currentHost}/game/join?game_id=${gameId}`:'';
    }, [gameId])

    return (
        <QRCode value={url} />
    )
};

export default GameQRCode;