import React from 'react';
import './RouletteWheel.style.css';

const RouletteWheel = ({})  => {
    return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px" viewBox="0 0 442.5 184.667" >
                <g id="background">
                    <path id="black_1_" d="M426.359,81.333h-65.378h-48.303c-0.953-8.027-2.931-15.74-5.808-23h58.489c0,0,11.635-1.92,16.141-11
                                c-4.506-9.08-16.141-11-16.141-11h-65.378h-5.57C277.539,14.25,250.936,0,221,0s-56.539,14.25-73.411,36.333h-5.07H77.141
                                c0,0-11.635,1.92-16.141,11c4.506,9.08,16.141,11,16.141,11h57.989c-2.877,7.26-4.855,14.973-5.808,23H81.519H16.141
                                c0,0-11.635,1.92-16.141,11c4.506,9.08,16.141,11,16.141,11h65.378h47.803c0.997,8.398,3.121,16.449,6.217,24H77.141
                                c0,0-11.635,1.92-16.141,11c4.506,9.08,16.141,11,16.141,11h65.378h5.837c16.905,21.514,43.161,35.333,72.644,35.333
                                s55.739-13.819,72.644-35.333h6.337h65.378c0,0,11.635-1.92,16.141-11c-4.506-9.08-16.141-11-16.141-11h-58.897
                                c3.096-7.551,5.22-15.602,6.217-24h48.303h65.378c0,0,11.635-1.92,16.141-11C437.994,83.253,426.359,81.333,426.359,81.333z"/>
                    <path id="yellow" className="st0" d="M426,82.333h-60.755h-53.794l0,0c-0.357-3.265-0.887-6.477-1.58-9.629
                                c-0.009-0.039-0.017-0.078-0.025-0.117c-0.456-2.063-0.983-4.099-1.577-6.107c-0.021-0.071-0.042-0.142-0.063-0.212
                                c-0.595-1.993-1.255-3.958-1.981-5.891c-0.024-0.065-0.049-0.129-0.074-0.194c-0.362-0.957-0.737-1.908-1.13-2.849H365
                                c0,0,10.812-1.562,15-10c-4.188-8.438-15-10-15-10h-60.755H293v-0.001c0-0.667-0.775-1.326-1.3-1.979
                                c-0.025-0.032,0.073-0.063,0.048-0.095c-0.523-0.649-0.993-1.291-1.534-1.926c-0.004-0.004,0.024-0.008,0.02-0.013
                                c-2.211-2.592-4.549-5.059-7.034-7.388c-0.038-0.036-0.069-0.072-0.108-0.108c-0.575-0.537-1.153-1.066-1.741-1.588
                                c-0.053-0.047-0.104-0.094-0.157-0.141c-0.609-0.537-1.223-1.067-1.846-1.588c-0.015-0.013-0.029-0.025-0.044-0.038
                                c-1.953-1.632-3.976-3.184-6.063-4.65c-0.058-0.041-0.117-0.081-0.175-0.122c-0.614-0.429-1.234-0.851-1.859-1.265
                                c-0.112-0.074-0.223-0.147-0.335-0.221c-0.589-0.387-1.183-0.767-1.781-1.14c-0.116-0.072-0.232-0.146-0.348-0.217
                                c-0.646-0.399-1.298-0.791-1.955-1.174c-0.055-0.032-0.108-0.065-0.163-0.096c-1.466-0.851-2.958-1.663-4.475-2.434
                                c-0.062-0.031-0.124-0.061-0.186-0.092c-0.664-0.335-1.332-0.662-2.005-0.981c-0.17-0.081-0.34-0.159-0.51-0.238
                                c-0.576-0.269-1.155-0.532-1.738-0.79c-0.2-0.088-0.4-0.176-0.6-0.263c-0.575-0.249-1.153-0.491-1.734-0.729
                                c-0.19-0.078-0.38-0.157-0.571-0.234c-0.658-0.263-1.32-0.519-1.985-0.767c-0.107-0.04-0.214-0.083-0.322-0.122
                                c-1.564-0.577-3.149-1.112-4.752-1.604c-0.212-0.065-0.426-0.126-0.639-0.19c-0.577-0.172-1.155-0.341-1.737-0.502
                                c-0.265-0.074-0.531-0.144-0.797-0.216c-0.542-0.145-1.085-0.285-1.63-0.42c-0.277-0.069-0.554-0.137-0.832-0.203
                                c-0.56-0.133-1.122-0.259-1.685-0.382c-0.255-0.056-0.51-0.113-0.766-0.167c-0.687-0.143-1.376-0.277-2.069-0.405
                                c-0.13-0.024-0.259-0.051-0.389-0.075c-0.827-0.149-1.659-0.286-2.493-0.412c-0.207-0.031-0.415-0.057-0.622-0.087
                                c-0.622-0.09-1.246-0.175-1.872-0.252c-0.297-0.037-0.596-0.069-0.894-0.102c-0.545-0.061-1.09-0.12-1.638-0.171
                                c-0.32-0.03-0.64-0.058-0.961-0.085c-0.54-0.045-1.081-0.085-1.624-0.121c-0.316-0.021-0.633-0.042-0.95-0.06
                                c-0.581-0.032-1.163-0.057-1.747-0.078c-0.275-0.01-0.549-0.024-0.824-0.031c-0.857-0.024-1.715-0.038-2.578-0.038
                                s-1.721,0.015-2.578,0.038c-0.275,0.008-0.549,0.021-0.824,0.031c-0.584,0.021-1.166,0.046-1.747,0.078
                                c-0.317,0.018-0.634,0.039-0.95,0.06c-0.543,0.036-1.084,0.076-1.624,0.121c-0.321,0.027-0.641,0.055-0.961,0.085
                                c-0.548,0.052-1.093,0.11-1.638,0.171c-0.298,0.034-0.597,0.066-0.894,0.102c-0.626,0.077-1.25,0.163-1.872,0.252
                                c-0.207,0.03-0.415,0.056-0.622,0.087c-0.835,0.126-1.666,0.263-2.493,0.412c-0.13,0.023-0.259,0.051-0.389,0.075
                                c-0.692,0.128-1.382,0.262-2.069,0.405c-0.256,0.053-0.511,0.111-0.766,0.167c-0.564,0.123-1.126,0.249-1.685,0.382
                                c-0.278,0.066-0.555,0.134-0.832,0.203c-0.545,0.135-1.089,0.275-1.63,0.42c-0.266,0.071-0.532,0.142-0.797,0.216
                                c-0.582,0.161-1.16,0.33-1.737,0.502c-0.213,0.064-0.427,0.125-0.639,0.19c-1.603,0.492-3.188,1.027-4.752,1.604
                                c-0.108,0.04-0.214,0.082-0.322,0.122c-0.665,0.248-1.327,0.504-1.985,0.767c-0.191,0.077-0.381,0.156-0.571,0.234
                                c-0.581,0.237-1.159,0.48-1.734,0.729c-0.201,0.087-0.4,0.174-0.6,0.263c-0.583,0.257-1.162,0.521-1.738,0.79
                                c-0.17,0.079-0.341,0.158-0.51,0.238c-0.673,0.319-1.341,0.646-2.005,0.981c-0.062,0.031-0.124,0.061-0.186,0.092
                                c-1.517,0.77-3.008,1.582-4.475,2.434c-0.055,0.032-0.108,0.065-0.163,0.096c-0.657,0.383-1.308,0.775-1.955,1.174
                                c-0.116,0.072-0.232,0.145-0.348,0.217c-0.599,0.373-1.192,0.753-1.781,1.14c-0.112,0.073-0.224,0.147-0.335,0.221
                                c-0.625,0.414-1.245,0.836-1.859,1.265c-0.058,0.041-0.117,0.081-0.175,0.122c-2.087,1.466-4.11,3.018-6.064,4.65
                                c-0.015,0.013-0.03,0.025-0.045,0.038c-0.623,0.521-1.238,1.051-1.847,1.588c-0.053,0.047-0.106,0.094-0.159,0.141
                                c-0.589,0.522-1.17,1.051-1.745,1.588c-0.038,0.036-0.077,0.072-0.116,0.108c-2.485,2.329-4.838,4.795-7.049,7.388
                                c-0.004,0.004-0.007,0.008-0.011,0.013c-0.541,0.634-1.072,1.277-1.596,1.926c-0.025,0.032,0.197,0.063,0.172,0.095
                                c-0.525,0.653-1.3,1.312-1.3,1.979v0.001h-10.745H77.5c0,0-10.813,1.562-15,10c4.187,8.438,15,10,15,10h59.479
                                c-0.393,0.942-0.768,1.892-1.13,2.849c-0.024,0.065-0.049,0.129-0.074,0.194c-0.725,1.933-1.386,3.898-1.981,5.891
                                c-0.021,0.071-0.042,0.142-0.063,0.212c-0.594,2.008-1.121,4.044-1.577,6.107c-0.009,0.039-0.017,0.078-0.025,0.117
                                c-0.693,3.152-1.223,6.364-1.58,9.629l0,0H77.255H16.5c0,0-10.813,1.562-15,10c4.187,8.438,15,10,15,10h60.755h53.294l0,0
                                c0.75,6.856,2.26,13.482,4.446,19.793c0.001,0.002,0.002,0.004,0.002,0.007c0.729,2.103,1.532,4.17,2.407,6.2H77.5
                                c0,0-10.813,1.562-15,10c4.187,8.438,15,10,15,10h60.755h11.014c0,0,0,0,0.001,0.001c1.021,1.306,2.078,2.583,3.168,3.831
                                c0.082,0.094,0.166,0.187,0.249,0.281c0.448,0.508,0.9,1.012,1.359,1.51c0.127,0.138,0.256,0.276,0.385,0.413
                                c0.425,0.456,0.854,0.907,1.288,1.354c0.14,0.144,0.281,0.289,0.422,0.432c0.443,0.45,0.89,0.894,1.342,1.335
                                c0.127,0.124,0.253,0.248,0.38,0.371c0.518,0.499,1.041,0.992,1.571,1.478c0.06,0.055,0.119,0.112,0.18,0.167
                                c1.215,1.111,2.459,2.188,3.733,3.233c0.094,0.077,0.19,0.152,0.285,0.229c0.525,0.427,1.054,0.849,1.589,1.264
                                c0.162,0.126,0.325,0.249,0.487,0.374c0.478,0.366,0.958,0.728,1.443,1.084c0.183,0.134,0.366,0.268,0.549,0.401
                                c0.481,0.348,0.965,0.691,1.452,1.029c0.18,0.125,0.359,0.25,0.54,0.374c0.529,0.361,1.062,0.716,1.598,1.067
                                c0.137,0.089,0.272,0.18,0.409,0.269c1.372,0.886,2.768,1.737,4.19,2.549c0.116,0.066,0.234,0.13,0.351,0.196
                                c0.58,0.328,1.162,0.651,1.75,0.967c0.206,0.111,0.414,0.219,0.621,0.328c0.506,0.267,1.014,0.53,1.525,0.787
                                c0.235,0.118,0.471,0.235,0.707,0.351c0.497,0.245,0.996,0.484,1.497,0.72c0.239,0.112,0.478,0.225,0.718,0.335
                                c0.523,0.24,1.05,0.474,1.578,0.705c0.215,0.094,0.43,0.19,0.646,0.283c0.643,0.275,1.29,0.541,1.94,0.801
                                c0.101,0.04,0.2,0.083,0.301,0.123c0.766,0.303,1.537,0.596,2.313,0.879c0.133,0.049,0.268,0.094,0.402,0.142
                                c0.627,0.225,1.255,0.445,1.888,0.656c0.249,0.083,0.501,0.163,0.751,0.244c0.523,0.17,1.048,0.337,1.575,0.497
                                c0.285,0.087,0.57,0.171,0.856,0.255c0.503,0.148,1.008,0.291,1.514,0.431c0.294,0.081,0.588,0.161,0.883,0.239
                                c0.517,0.137,1.036,0.267,1.556,0.395c0.28,0.069,0.56,0.139,0.841,0.205c0.586,0.138,1.174,0.267,1.764,0.393
                                c0.216,0.046,0.43,0.096,0.646,0.141c0.809,0.167,1.621,0.324,2.437,0.469c0.122,0.022,0.245,0.04,0.368,0.061
                                c0.691,0.12,1.384,0.234,2.08,0.338c0.278,0.042,0.557,0.078,0.835,0.117c0.546,0.077,1.092,0.151,1.64,0.218
                                c0.318,0.039,0.636,0.074,0.955,0.109c0.518,0.058,1.037,0.112,1.557,0.16c0.327,0.031,0.655,0.06,0.983,0.087
                                c0.525,0.044,1.052,0.081,1.579,0.116c0.32,0.021,0.639,0.043,0.96,0.061c0.57,0.031,1.141,0.055,1.713,0.076
                                c0.276,0.01,0.552,0.024,0.829,0.031c0.848,0.023,1.698,0.037,2.551,0.037s1.703-0.014,2.551-0.037
                                c0.277-0.008,0.552-0.021,0.829-0.031c0.572-0.021,1.144-0.044,1.713-0.076c0.321-0.018,0.64-0.04,0.96-0.061
                                c0.528-0.035,1.054-0.073,1.579-0.116c0.328-0.027,0.656-0.057,0.983-0.087c0.521-0.049,1.039-0.103,1.557-0.16
                                c0.319-0.035,0.637-0.07,0.955-0.109c0.549-0.067,1.095-0.141,1.64-0.218c0.278-0.039,0.558-0.075,0.835-0.117
                                c0.696-0.104,1.389-0.218,2.08-0.338c0.122-0.021,0.246-0.039,0.368-0.061c0.816-0.145,1.628-0.302,2.437-0.469
                                c0.216-0.045,0.43-0.095,0.646-0.141c0.59-0.126,1.179-0.256,1.764-0.393c0.281-0.066,0.561-0.137,0.841-0.205
                                c0.52-0.128,1.04-0.258,1.556-0.395c0.295-0.078,0.589-0.158,0.883-0.239c0.507-0.139,1.011-0.283,1.514-0.431
                                c0.286-0.084,0.572-0.168,0.856-0.255c0.527-0.161,1.052-0.328,1.575-0.497c0.25-0.081,0.502-0.16,0.751-0.244
                                c0.633-0.212,1.262-0.431,1.888-0.656c0.134-0.048,0.269-0.093,0.402-0.142c0.776-0.283,1.547-0.576,2.313-0.879
                                c0.101-0.04,0.2-0.083,0.301-0.123c0.65-0.26,1.297-0.527,1.94-0.801c0.216-0.092,0.43-0.189,0.646-0.283
                                c0.528-0.231,1.055-0.464,1.578-0.705c0.24-0.11,0.479-0.223,0.718-0.335c0.502-0.236,1.001-0.476,1.497-0.72
                                c0.236-0.116,0.472-0.233,0.707-0.351c0.511-0.258,1.019-0.521,1.525-0.787c0.207-0.109,0.415-0.217,0.621-0.328
                                c0.587-0.316,1.17-0.639,1.75-0.967c0.117-0.066,0.234-0.13,0.351-0.196c1.421-0.813,2.818-1.664,4.19-2.549
                                c0.137-0.089,0.272-0.18,0.409-0.269c0.536-0.351,1.07-0.705,1.598-1.067c0.181-0.124,0.36-0.249,0.54-0.374
                                c0.488-0.339,0.972-0.681,1.452-1.029c0.184-0.133,0.367-0.266,0.549-0.401c0.485-0.357,0.966-0.718,1.443-1.084
                                c0.162-0.124,0.326-0.248,0.487-0.374c0.535-0.415,1.064-0.837,1.589-1.264c0.095-0.077,0.19-0.152,0.285-0.229
                                c1.274-1.044,2.518-2.122,3.733-3.233c0.06-0.055,0.119-0.112,0.18-0.167c0.529-0.487,1.053-0.979,1.571-1.478
                                c0.128-0.123,0.253-0.248,0.38-0.371c0.452-0.44,0.899-0.885,1.342-1.335c0.141-0.143,0.282-0.288,0.422-0.432
                                c0.434-0.447,0.863-0.898,1.288-1.354c0.128-0.138,0.257-0.275,0.385-0.413c0.459-0.498,0.911-1.002,1.359-1.51
                                c0.083-0.094,0.167-0.186,0.249-0.281c1.09-1.248,2.147-2.525,3.168-3.831c0,0,0,0,0.001-0.001h11.514H365c0,0,10.812-1.562,15-10
                                c-4.188-8.438-15-10-15-10h-60.404c0.875-2.029,1.678-4.097,2.407-6.2c0.001-0.002,0.002-0.004,0.002-0.007
                                c2.186-6.312,3.696-12.938,4.446-19.793l0,0h53.794H426c0,0,10.812-1.562,15-10C436.812,83.895,426,82.333,426,82.333z
                                 M221,179.083c-47.911,0-86.75-38.839-86.75-86.75S173.089,5.583,221,5.583s86.75,38.839,86.75,86.75S268.911,179.083,221,179.083z
                                "/>
                </g>
                <g id="wheel">
                    <path id="red" className="st0" d="M203.303,163.936l7.331-30.378c-1.669-0.442-3.331-0.978-4.975-1.637
                                    c-0.576-0.231-1.14-0.479-1.701-0.732l-12.124,28.758C195.233,161.424,199.497,162.956,203.303,163.936z M226.242,166.041
                                    l-2.767-31.122c-2.342,0.113-4.708,0.036-7.076-0.249l-2.13,31.172C218.271,166.235,222.274,166.297,226.242,166.041z
                                     M163.666,45.674l24.601,20.361c1.306-1.638,2.723-3.165,4.235-4.573l-22.174-23.048C167.959,40.672,165.732,43.095,163.666,45.674
                                    z M286.757,58.437c-2.238-4.346-4.904-8.474-7.974-12.319l-24.372,19.64c1.791,2.21,3.346,4.588,4.653,7.091L286.757,58.437z
                                     M293.856,105.364c0.738-4.102,1.099-8.213,1.138-12.297L263.9,93.695c-0.058,2.087-0.27,4.185-0.646,6.277L293.856,105.364z
                                     M294.142,81.126c-0.71-4.607-1.855-9.122-3.404-13.491l-29.127,11.14c0.87,2.539,1.501,5.162,1.879,7.837L294.142,81.126z
                                     M272.137,38.847c-3.254-3.113-6.811-5.959-10.667-8.473l-16.451,26.823c1.808,1.203,3.499,2.534,5.073,3.972L272.137,38.847z
                                     M226.242,166.041c-3.967,0.257-7.971,0.194-11.973-0.2l2.13-31.172c-1.925-0.232-3.85-0.604-5.765-1.111l-7.331,30.378
                                    c-3.807-0.98-8.07-2.513-11.469-3.989l12.124-28.758c-2.055-0.928-4.005-1.998-5.838-3.2l-15.641,27.52
                                    c3.453,2.1,7.113,3.959,10.989,5.512c15.039,6.028,30.882,6.752,45.481,3.089l-8.112-30.196c-2.409,0.548-4.872,0.885-7.363,1.005
                                    L226.242,166.041z M236.994,132.01l11.486,29.009c4.57-1.829,8.955-4.108,13.086-6.811l-16.983-26.103
                                    C242.187,129.659,239.644,130.967,236.994,132.01z M257.556,114.841l27.113,15.223c1.902-3.219,3.771-7.086,5.205-10.664
                                    c0.125-0.311,0.167-0.472,0.535-1.402l-28.852-11.564c-0.218,0.627-0.448,1.253-0.697,1.875
                                    C259.934,110.619,258.819,112.793,257.556,114.841z M269.413,148.292c2.905-2.509,5.627-5.255,8.135-8.228l-24.207-19.518
                                    c-1.173,1.335-2.424,2.584-3.741,3.749L269.413,148.292z M163.666,45.674c2.066-2.578,4.293-5.002,6.662-7.26l22.174,23.048
                                    c1.897-1.767,3.946-3.342,6.112-4.722l-17.559-26.68c-10.051,6.435-18.619,15.34-24.695,26.283l27.874,15.768
                                    c1.184-2.165,2.533-4.195,4.033-6.076L163.666,45.674z M211.493,51.256l-8.93-30.581c-4.381,1.131-8.643,2.665-12.738,4.567
                                    l14.383,28.491C206.555,52.69,208.993,51.862,211.493,51.256z M150.619,69.533c-1.323,4.072-2.276,8.185-2.869,12.303l31.841,3.627
                                    c0.362-2.134,0.889-4.264,1.594-6.373L150.619,69.533z M181.32,106.323l-30.016,10.83c1.64,4.601,3.726,9.036,6.238,13.233
                                    l27.239-16.502C183.384,111.481,182.225,108.949,181.32,106.323z M163.144,138.458c2.804,3.524,5.942,6.807,9.395,9.798
                                    l20.053-24.607c-1.555-1.442-2.989-2.991-4.297-4.631L163.144,138.458z M146.996,93.752c0.091,4.661,0.632,9.273,1.583,13.783
                                    l31.127-7.344c-0.475-2.568-0.713-5.189-0.704-7.832L146.996,93.752z M238.814,53.783l11.79-29.245
                                    c-0.689-0.3-1.371-0.611-2.073-0.892c-2.582-1.035-5.19-1.9-7.809-2.628l-9.467,30.19c2.013,0.48,4.015,1.109,5.993,1.902
                                    C237.779,53.322,238.297,53.552,238.814,53.783z"/>
                    <path id="black" className="st1" d="M238.814,53.783l11.798-29.267c3.836,1.672,7.462,3.635,10.862,5.85l-16.456,26.831
                                    C243.079,55.906,241.006,54.764,238.814,53.783z M231.255,51.207l9.471-30.203c-0.855-0.238-1.712-0.459-2.569-0.665
                                    c-3.367-0.807-6.751-1.367-10.129-1.691l-2.829,31.575C227.218,50.403,229.241,50.727,231.255,51.207z M254.412,65.757
                                    L278.8,46.105c-2.042-2.557-4.261-4.989-6.648-7.272l-22.06,22.337C251.654,62.597,253.095,64.132,254.412,65.757z M263.9,93.695
                                    l31.116-0.628c0.039-4.021-0.253-8.015-0.859-11.944l-30.668,5.489C263.819,88.944,263.966,91.311,263.9,93.695z M261.557,106.434
                                    l28.875,11.573c1.488-3.713,2.831-8.381,3.71-13.09l-30.777-5.661C262.976,101.662,262.382,104.062,261.557,106.434z
                                     M261.61,78.775l29.145-11.147c-1.118-3.153-2.447-6.227-3.979-9.201l-27.711,14.422C260.062,74.757,260.912,76.738,261.61,78.775z
                                     M179.706,100.191l-31.355,7.398c0.688,3.273,1.596,6.498,2.718,9.649l30.252-10.915
                                    C180.632,104.326,180.092,102.277,179.706,100.191z M179.59,85.463l-32.074-3.653c-0.574,3.98-0.817,7.977-0.74,11.952
                                    l32.226-1.403C179.009,90.069,179.2,87.764,179.59,85.463z M182.048,76.721c0.642-1.601,1.38-3.134,2.187-4.611l-28.033-15.858
                                    c-1.969,3.482-4.332,8.654-5.831,13.204l30.814,9.635C181.45,78.297,181.732,77.507,182.048,76.721z M184.781,113.884
                                    l-27.449,16.629c1.683,2.807,3.558,5.512,5.62,8.093l25.342-19.588C186.995,117.39,185.823,115.674,184.781,113.884z
                                     M192.591,123.649l-20.185,24.77c3.075,2.649,6.407,5.077,9.986,7.245l15.729-27.675
                                    C196.138,126.688,194.296,125.23,192.591,123.649z M218.345,50.172l-4.081-31.914c-4.002,0.391-7.942,1.107-11.784,2.129
                                    l9.013,30.869C213.735,50.712,216.025,50.344,218.345,50.172z M204.209,53.733l-14.477-28.677c-3.022,1.434-5.945,3.07-8.747,4.897
                                    l17.629,26.787C200.405,55.598,202.274,54.593,204.209,53.733z M170.328,38.414c-2.369,2.258-4.596,4.682-6.662,7.26l24.601,20.361
                                    c1.306-1.638,2.723-3.165,4.235-4.573L170.328,38.414z M203.303,163.936l7.331-30.378c-1.669-0.442-3.331-0.978-4.975-1.637
                                    c-0.576-0.231-1.14-0.479-1.701-0.732l-12.124,28.758C195.233,161.424,199.497,162.956,203.303,163.936z M216.399,134.669
                                    l-2.13,31.172c4.002,0.394,8.006,0.456,11.973,0.2l-2.767-31.122C221.132,135.032,218.767,134.955,216.399,134.669z
                                     M277.814,140.278c2.633-3.089,5.043-6.433,7.193-10.023l-27.452-15.414c-1.257,2.039-2.667,3.944-4.214,5.704L277.814,140.278z
                                     M244.583,128.105l17.09,26.267c2.751-1.772,5.395-3.733,7.909-5.877l-19.98-24.201
                                    C248.019,125.693,246.341,126.965,244.583,128.105z M230.838,133.914l8.112,30.196c3.251-0.788,6.446-1.795,9.561-3.013
                                    l-11.517-29.087C234.995,132.797,232.939,133.436,230.838,133.914z"/>
                    <path id="green" className="st2" d="M218.422,50.778l-4.109-32.136c4.525-0.414,9.112-0.415,13.712,0.026l-2.907,32.449
                                    c-2.219-0.223-4.434-0.263-6.625-0.129L218.422,50.778z"/>
                    <path id="strips" d="M287.074,118.994c14.617-36.467-3.16-78.027-39.628-92.643s-78.027,3.16-92.643,39.628
                                    c-14.616,36.467,3.16,78.027,39.628,92.643C230.898,173.239,272.458,155.461,287.074,118.994z M155.731,66.351
                                    c14.411-35.955,55.388-53.483,91.343-39.072s53.483,55.388,39.072,91.343c-14.411,35.956-55.388,53.483-91.343,39.072
                                    S141.32,102.307,155.731,66.351z M274.372,113.726c11.796-29.43-2.551-62.969-31.98-74.765s-62.969,2.551-74.765,31.98
                                    c-11.796,29.43,2.551,62.969,31.98,74.765C229.037,157.502,262.577,143.156,274.372,113.726z M168.556,71.313
                                    c11.591-28.918,44.546-43.015,73.464-31.424s43.015,44.546,31.424,73.464s-44.546,43.015-73.464,31.424
                                    C171.062,133.187,156.965,100.231,168.556,71.313z"/>
                    <path id="spinner" className="st0" d="M222.413,130.472c7.178-0.266,1.206-5.557,0.683-8.179c-0.681-3.416,1.095-11.323,1.87-14.88
                                    c2.435-11.171,10.623-11.666,21.182-12.385c1.927-0.131,5.053-1.389,6.734-1.177c2.79,0.351,3.918,6.201,6.349,0.155
                                    c0.569-1.415,0.252-4.954-1.241-5.808c-3.56-2.039-3.062,2.486-5.83,2.91c-2.924,0.448-9.99-0.763-13.077-1.222
                                    c-4.734-0.704-9.879-2.449-12.394-6.554c-2.017-3.293-2.768-8.722-2.871-13.149c-0.043-1.861-0.41-4.678-0.176-6.423
                                    c0.125-0.93-0.391-2.505-0.38-3.328c0.023-1.649,3.066-1.581,2.126-3.91c-0.867-2.147-5.244-2.573-6.993-1.389
                                    c-4.191,2.837,0.184,2.405,0.508,5.046c0.405,3.302,0.555,9.689-0.094,13.973c-1.532,10.117-6.721,14.987-17.191,16.125
                                    c-2.383,0.259-5.647-0.08-8.095-0.209c-0.828-0.044-1.723,0.919-2.558,0.835c-0.813-0.082-2.658-1.527-2.939-1.511
                                    c-2.228,0.124-4.978-0.436-4.514,4.077c0.402,3.91,1.964,3.285,4.456,3.159c1.192-0.06,0.924-2.209,2.169-2.523
                                    c1.628-0.411,2.692,0.932,4.12,0.888c2.579-0.079,6.298-0.202,8.837,0.182c5.147,0.779,8.956,1.556,11.894,5.825
                                    c2.307,3.352,1.877,2.374,3.036,6.62c0.949,3.476,0.685,6.008,0.931,9.585c0.136,1.978,1.395,5.022,1.177,6.734
                                    C219.801,126.546,213.35,130.807,222.413,130.472z"/>
                </g>
                <g id="ball">
                    <circle id="visible-ball" className="st0" cx="221.238" cy="12.027" r="4.75"/>
                    <circle id="hidden-ball" cx="220.762" cy="172.639" r="4.75"/>
                </g>
            </svg>
    )
};

export default RouletteWheel;