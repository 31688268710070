import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
const GameContext = createContext();

const StoreKeys = {
    GAME_TOKEN: 'game_token',
    GAME_ID: 'game_id'
}

export const GameProvider = ({ children }) => {
    const [gameCtxLoaded, setGameCtxLoaded] = useState(false);

    const [gameToken, setGameToken] = useState(null);
    const isEnrolled = useMemo(() => {
        return !!gameToken
    }, [gameToken]);

    useEffect(() => {
        const gameToken = localStorage.getItem(StoreKeys.GAME_TOKEN) || '';
        if(!gameToken) {
            setGameCtxLoaded(true);
            return;
        }
        setGameToken(gameToken);
        setGameCtxLoaded(true);
    }, []);

    const setEnrollment = (gameToken) => {
        setGameToken(gameToken);
        localStorage.setItem(StoreKeys.GAME_TOKEN, gameToken);
    }

    const clearEnrollment = () => {
        setGameToken(null)
        localStorage.removeItem(StoreKeys.GAME_TOKEN);
    }

    return (
        <GameContext.Provider value={{
            gameCtxLoaded, gameToken, isEnrolled,
            setEnrollment, clearEnrollment
        }}>
            {children}
        </GameContext.Provider>
    );
};

export const useGameCtx = () => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useRoom must be used within a GameProvider');
    }
    return context;
};