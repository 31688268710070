import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Flex, Input, Result, Row, Space, Spin, Statistic} from "antd";
import {
    useParams
} from "react-router-dom";
import CountUp from "react-countup";
import {Backend} from "../../../service";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import { currencyFormat } from '../../../common/currency';

import RoundsTable from "../../../components/Tables/RoundsTable/RoundsTable.component";

const counterFormatter = (value) => <CountUp end={value} separator="," />;
const currencyFormatter = (value) => currencyFormat(value);

const AdminGameDetail = () => {
    const navigate = useNavigate();

    let { gameShortId } = useParams();

    const [creatingRound, setCreatingRound] = useState(false);

    const [gameDetail, setGameDetail] = useState(null);
    const [gameDetailLoading, setGameDetailLoading] = useState(true);

    const [gameClosing, setGameClosing] = useState(false);

    const [rounds, setRounds] = useState([]);
    const [roundsFetching, setRoundFetching] = useState(true);

    const [betAmounts, setBetAmounts] = useState('');
    const [updatingBetAmounts, setUpdatingBetAmounts] = useState(false);

    const loading = useMemo(() => {
        return gameDetailLoading ||
            creatingRound ||
            roundsFetching ||
            gameClosing ||
            updatingBetAmounts;
    }, [gameDetailLoading, creatingRound, roundsFetching, gameClosing, updatingBetAmounts]);

    const goToHome = useCallback(() => {
        navigate('/admin/home');
    }, [navigate]);

    const goToRoundDetail = useCallback((roundId) => {
        if(!gameDetail) {
            toast.error('Game detail does not exist!')
            return
        }
        navigate(`/admin/game/${gameDetail.shortId}/round/${roundId}`);
    }, [navigate, gameDetail]);

    const updateGameDetail = useCallback(() => {
        setGameDetailLoading(true)
        Backend.Admin.GameService.getGameDetailAdmin(gameShortId).then((res) => {
            if(!res.success) {
                toast.error(res.message)
                return
            }
            setGameDetail(res.data);
        })
        .finally(() => {
            setGameDetailLoading(false);
        })
    }, [gameShortId]);

    const closeGame = useCallback(() => {
        if(!gameDetail) {
            toast.warn("Game detail does not exist!")
            return
        }

        setGameClosing(true)
        Backend.Admin.GameService.closeGame(gameShortId).then((res) => {
            if(!res.success) {
                toast.error(res.message)
                return
            }
            goToHome();
        })
            .finally(() => {
                setGameClosing(false);
            })
    }, [gameDetail]);

    const updateRounds = useCallback(() => {
        if(!gameDetail) {
            setRoundFetching(false)
            setRounds([])
            return;
        }

        setRoundFetching(true)
        Backend.Admin.RoundService.findAllByGameId(gameDetail.shortId)
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message)
                    setRounds([]);
                    return
                }
                setRounds(res.data);
            })
            .finally(() => {
                setRoundFetching(false);
            })
    }, [gameDetail]);

    const createNewRound = useCallback(() => {
        if(!gameDetail || !gameDetail.shortId ) {
            toast.error("Game detail does not exist!")
            return
        }
        setCreatingRound(true);
        Backend.Admin.RoundService.createNewRound(gameDetail.shortId)
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message);
                    return
                }
                toast('Round created')
               goToRoundDetail(res.data.roundId);
            })
            .finally(() => {
                setCreatingRound(false)
            });
    }, [gameDetail, goToRoundDetail]);

    const updateBetAmounts = useCallback(() => {
        if(!gameDetail || !betAmounts ) {
            toast.error("Game detail does not exist!")
            return
        }

        const betAmn = betAmounts.split(',').map(Number).filter(s => !isNaN(s));
        if(betAmn.length < 3 || betAmn.length > 6) {
            toast.error("Invalid bets!")
            return
        }

        setUpdatingBetAmounts(true);
        Backend.Admin.GameService.updateBetAmounts(gameDetail.shortId, betAmn)
            .then((res) => {
                if(!res.success) {
                    toast.error(res.message);
                    return
                }
                toast('Bets updated')
            })
            .finally(() => {
                setUpdatingBetAmounts(false)
            });
    }, [gameDetail, betAmounts]);

    useEffect(() => {
        updateGameDetail();
    }, [])

    useEffect(() => {
        updateRounds();
        if(!gameDetail || !gameDetail.betAmounts) {
            setBetAmounts('');
        } else {
            setBetAmounts(gameDetail.betAmounts.join(','));
        }
    }, [gameDetail])

    if(!gameDetail && gameDetailLoading) {
        return <div className="page-container">
            <Spin fullscreen tip="Yükleniyor..."/>
        </div>
    }

    if(!gameDetail) {
        return <div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", paddingTop: "70px" }}>
            <div style={{ backgroundColor: "white", width: "40vw", height: "60vh" }}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the game you visited does not exist."
                    extra={<Button type="primary" onClick={goToHome}>Back Home</Button>}
                />
            </div>
        </div>
    }

    return (
        <div className="page-container" style={{alignItems: 'flex-start'}}>
            {
                loading && <Spin fullscreen tip="Yükleniyor..."/>
            }
            <div className="admin-detail-container">
                <div className="admin-detail-header">
                    <h1>
                        Game {gameDetail.gameName}
                    </h1>
                </div>
                <div className="admin-detail-block admin-round-actions">
                    <Button block onClick={closeGame} type="primary">Close Game</Button>
                </div>
                <div className="admin-detail-block">
                    <div className="admin-detail-statistic">
                        <Card bordered={false} size="default" className="static-card">
                            <Statistic
                                title="Enrolled Users"
                                value={gameDetail.userCount}
                                valueStyle={{ color: '#3f8600' }}
                                formatter={counterFormatter}
                            />
                        </Card>
                        <Card bordered={false} className="static-card">
                            <Statistic
                                title="Round Count"
                                value={gameDetail.roundCount}
                            />
                        </Card>
                        <Card bordered={true} className="static-card">
                            <Statistic
                                title="Active Bet Count"
                                value={gameDetail.summary.activeCount}
                            />
                        </Card>
                        <Card bordered={false} className="static-card">
                            <Statistic
                                title="Total Bet"
                                value={gameDetail.summary.totalBetAmount}
                                formatter={currencyFormatter}

                            />
                        </Card>
                        <Card bordered={false} className="static-card">
                            <Statistic
                                title="Total Win"
                                value={gameDetail.summary.totalWinAmount}
                                formatter={currencyFormatter}
                            />
                        </Card>
                    </div>
                </div>
                <div className="admin-detail-block">
                    <Input.Search
                        value={betAmounts}
                        onChange={(e) => setBetAmounts(e.target.value)}
                        onSearch={updateBetAmounts}
                        disabled={updatingBetAmounts}
                    ></Input.Search>
                </div>
                <div className="admin-detail-block">
                    <Button onClick={updateGameDetail} type="primary" block>Refresh</Button>
                </div>
                {
                    gameDetail.status === "ACTIVE" && !gameDetail.activeRound &&
                    <div className="admin-detail-block admin-table-buttons">
                        <Button onClick={createNewRound}>Create New Round</Button>
                    </div>
                }
                <RoundsTable data={rounds} loading={roundsFetching} goToRoundDetail={goToRoundDetail}/>
            </div>
        </div>
    );
};

export default AdminGameDetail;