import React from 'react';
import './chipBox.style.css';
import SvgChip from "../SvgChip/SvgChip.component";

const ChipBox = (props)  => {
    return (
        <div className={"chip-box" + (` ${(props.className || '')}`) + (props.selected ? ' selected-chip' : '')} onClick={props.onClick}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 200 200"
                 preserveAspectRatio="xMidYMid meet">
                <SvgChip {...props} x={0} y={0} isScaled={false} containerClass=""></SvgChip>
            </svg>
        </div>
    )
};

export default ChipBox;