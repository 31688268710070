import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import io from 'socket.io-client';
import { useAuthCtx } from './AuthContexts';
import { BACKEND_HOST } from '../constants/api.constants';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

let glb_socket = null;
export const SocketProvider = ({ children }) => {
    const { authToken } = useAuthCtx();
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [serverTime, setServerTime] = useState(null);
    const heartbeatIntervalRef = useRef(null);

    useEffect(() => {
        if (authToken) {
            if(glb_socket) {
                glb_socket.disconnect();
                glb_socket = null;
            }

            glb_socket = io(BACKEND_HOST, {
               auth: {
                   token: authToken
               },
               reconnection: true,
               reconnectionDelay: 1000,
               reconnectionDelayMax: 5000,
            });

            glb_socket.timeout()

            setSocket(glb_socket);

            glb_socket.on('connect', () => {
                console.log('Socket connected');
                setIsConnected(true)
            });

            glb_socket.on('disconnect', () => {
                console.log('Socket disconnected');
                setIsConnected(false);
            });

            glb_socket.on('reconnect_attempt', () => {
                console.log('Attempting to reconnect...');
            });

            glb_socket.on('reconnect', (attemptNumber) => {
                console.log(`Socket reconnected after ${attemptNumber} attempts`);
                setIsConnected(true);
            });

            glb_socket.on('heartbeat', (data) => {
                setServerTime(data.serverTime);
            });

            return () => {
                glb_socket.disconnect();
            };
        } else {
            if(glb_socket) {
                glb_socket.disconnect();
                glb_socket = null;
            }
            setSocket(null);
        }

    }, [authToken]);


    // useEffect(() => {
    //     if(heartbeatIntervalRef.current) {
    //         clearInterval(heartbeatIntervalRef.current);
    //     }
    //
    //     heartbeatIntervalRef.current = setInterval(() => {
    //         if()
    //     }, 3000);
    //
    //     return () => {
    //         if(heartbeatIntervalRef.current) {
    //             clearInterval(heartbeatIntervalRef.current);
    //         }
    //     }
    // }, [isConnected, socket])

    return (
        <SocketContext.Provider value={{socket, isConnected, serverTime}}>
            {children}
        </SocketContext.Provider>
    );
};