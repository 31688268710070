import * as Moment from 'moment';

export const dateFormat = (date) => {
    if(!date) {
        return '-'
    }
    date = Moment(date);
    if(!date.isValid()) {
        return '-'
    }
    return date.format('DD MMM YY - HH:mm:ss')
}