import React, {useState} from 'react';
import {Alert, Flex, Spin, Input, QRCode, Space} from "antd";
import {useSearchParams} from "react-router-dom";


const QRPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [gameId, setGameId] = useState(searchParams.get('game_id') || '');

    let port = null;
    if(window.location.port !== '80' || window.location.port !== '') {
        port = ':' + window.location.port;
    }
    const currentHost = `${window.location.protocol}//${window.location.hostname}${port}`;
    const url = gameId ? `${currentHost}/game/join?game_id=${gameId}`:'';

    return (
        <div className="bg-container">
            <Flex align="center" gap="middle">
                <Space direction="vertical" align="center">
                    <div style={{background: 'white'}}>
                        <QRCode value={url} />
                    </div>
                    <Input
                        placeholder="-"
                        maxLength={60}
                        value={gameId}
                        onChange={(e) => setGameId(e.target.value)}
                    />
                </Space>
            </Flex>
        </div>
    );
};

export default QRPage;