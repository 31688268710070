import React from 'react';
import './summary.style.css';

const SummaryBox = ({ balance = 0, totalBet = 0, lastWin = 0,
                        winningNumber=null, lastWinningNo = null})  => {
    return (
        <div className="summary">
            <div className="summary-box">
                <span id="balance-label">KASA: </span>
                <span id="balance">{balance}</span>
            </div>

            <div className="summary-box">
                <span id="bet-label">BET: </span>
                <span id="bet">{totalBet}</span>
            </div>

            <div className="summary-box">
                <span id="lastwin-label">SON KAZANÇ: </span>
                <span id="lastwin">{lastWin}</span>
            </div>
            <div className="summary-box">
                <span>KAZANAN SAYI: </span>
                <span>{winningNumber != null ? winningNumber : '--'}</span>
            </div>
            <div className="summary-box">
                <span>SON SAYI: </span>
                <span>{lastWinningNo != null ? lastWinningNo : '--'}</span>
            </div>
        </div>
    )
};

export default SummaryBox;