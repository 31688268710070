import {api, safeCall } from './api';

export const login = async (username, password) => {
    return safeCall(api.post('/auth/login', {
        username,
        password
    }));
};

export const whoami = async (token) => {
    return safeCall(api.get('/auth/whoami', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }));
};