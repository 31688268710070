import {Navigate, useLocation, useSearchParams} from "react-router-dom";
import {useAuthCtx} from "../contexts/AuthContexts";
import {useGameCtx} from "../contexts/GameContexts";
import React, {useMemo} from "react";
import LoadingPage from "../pages/Loading/Loading.page";

const GamePaths = [
    "/game/join",
    "/game/play",
];

const AdminPaths = [
    "/admin/home",
    /\/admin\/game\/(.*)/,
    /\/admin\/user\/(.*)/,
    "/admin/bets",
];

const AllPaths = [
    ...AdminPaths,
    ...GamePaths
];

function checkPaths(paths, searchPath) {
    return paths.some(p => {
        if(typeof p === "string") {
            return p === searchPath;
        }
        if( p instanceof RegExp) {
            return p.test(searchPath)
        }
        return false;
    })
}

function checkIsAdminPath(isAdmin, path) {
    return isAdmin && checkPaths(AdminPaths, path);
}

const ProtectedRoute = ({ element }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { loggedIn, authCtxLoaded, isAdmin } = useAuthCtx();
    const { isEnrolled, gameCtxLoaded, clearEnrollment } = useGameCtx();

    const loaded = useMemo(() => {
        return authCtxLoaded && gameCtxLoaded
    }, [authCtxLoaded, gameCtxLoaded]);

    const location = useLocation();

    if(!loaded) {
        return <LoadingPage />
    }

    const gameIdQuery = searchParams.get('game_id');
    const path = location.pathname;

    if (!loggedIn) {
        if(location.pathname !== "/login") {
            if(location.pathname === '/game/join' && gameIdQuery) {
                localStorage.setItem('q_game_id', gameIdQuery)
            }
            return <Navigate to="/login" />;
        }
        return element;
    }

    const isAdminRouting = loggedIn && checkIsAdminPath(isAdmin, path);
    if(isAdminRouting) {
        return element;
    }

    if(!checkPaths(AllPaths, path)) {
        let navPath = '/login';
        if(isAdmin) {
            navPath = '/admin/home'
        } else if (loggedIn && isEnrolled) {
            navPath = '/game/play'
        } else if (loggedIn) {
            navPath = '/game/join'
        }
        return <Navigate to={navPath} />;
    }

    if (!isEnrolled && location.pathname !== "/game/join") {
        return <Navigate to="/game/join" />;
    }

    if(!!gameIdQuery && location.pathname === "/game/join") {
        clearEnrollment();
        return element;
    }

    const storedGameId = localStorage.getItem('q_game_id');
    if(location.pathname === "/game/join" && !!storedGameId) {
        localStorage.removeItem('q_game_id')
        return <Navigate to={"/game/join?game_id=" + storedGameId} />;
    }

    if(isEnrolled && location.pathname !== "/game/play") {
        return <Navigate to="/game/play" />;
    }

    if(location.pathname.startsWith('/admin')) {
        return <Navigate to="/game/join" />;
    }

    return element;
};

export default ProtectedRoute;