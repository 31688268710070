import React, {useMemo} from "react";
import {Table} from "antd";
import { currencyFormat } from '../../../common/currency';
import {dateFormat} from "../../../common/date";

const getColumns = () => [
    {
        title: 'Transaction',
        dataIndex: 'type',
        key: 'type',
        filters: [
            {
                text: 'DEPOSIT',
                value: 'DEPOSIT',
            },
            {
                text: 'WITHDRAW',
                value: 'WITHDRAW',
            },
            {
                text: 'BET_PLACE',
                value: 'BET_PLACE',
            },
            {
                text: 'BET_CANCEL',
                value: 'BET_CANCEL',
            },
            {
                text: 'BET_WIN',
                value: 'BET_WIN',
            },
        ],
        onFilter: (value, record) => record.type === value,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (_, { amount }) => currencyFormat(amount),
    },
    {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, { createdAt }) => dateFormat(createdAt),
    },
];

const TransactionTable = ({ loading, data }) => {
    const columns = useMemo(() => {
        return getColumns()
    }, []);
    return <Table
        expandable
        columns={columns}
        dataSource={data}
        loading={loading}
        tableLayout="fixed"
        rowClassName={(record) => {
            return record.amount >= 0 ? 'active-table-row' : 'closed-table-row'
        }}
    />
}

export default TransactionTable;