import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {useGameCtx} from '../../contexts/GameContexts';
import {useNavigate} from 'react-router-dom';
import {useSocket} from "../../contexts/SocketContext";
import useScreenOrientation from '../../hooks/orientationLock';

import RouletteTable from "../../components/RouletteTable/RouletteTable.component";
import ChipBox from "../../components/ChipBox/ChipBox.component";
import SummaryBox from "../../components/SummaryBox/SummaryBox.component";

import {Backend, SocketEvents} from '../../service';

import {BetChipStyles} from "../../constants/betChip.constants";
import {BetBoxMap} from "../../constants/betbox.constants";
import {useAuthCtx} from "../../contexts/AuthContexts";
import ResultBox from "../../components/ResultBox/ResultBox.component";

import {Button, Flex, Modal, Popconfirm} from "antd";
import RouletteWheel from "../../components/RouletteWheel/RouletteWheel.component";

import "./Game.style.css";
import GameQRCode from "../../components/GameQRCode/GameQRCode.component";

const LastWinNoLimit = 100;

let nextBetId = 1000;
const GamePage = ({}) => {
    const navigate = useNavigate();

    const screenOrientation = useScreenOrientation();
    console.log(screenOrientation);

    const {socket, isConnected, serverTime} = useSocket();
    const {logout, updateBalance, fetchBalance} = useAuthCtx();
    const {isEnrolled, gameToken, clearEnrollment} = useGameCtx();

    const {loggedInUser} = useAuthCtx();
    const [lastWin, setLastWin] = useState(0)

    const [joining, setJoining] = useState(false);

    const [gameInfo, setGameInfo] = useState(null);
    const [round, setRound] = useState(null);
    const [selectedChipId, setSelectedChipId] = useState(1);

    const [lastWinningNo, setLastWinningNo] = useState(null);
    const [lastWinningNumbers, setLastWinningNumbers] = useState([]);
    const [isLastWinningModalOpen, setIsLastWinningModalOpen] = useState(false);

    useEffect(() => {
        fetchBalance();
    }, [])

    useEffect(() => {
        if(!gameInfo || !gameInfo.shortId) {
            localStorage.removeItem('last_game_id')
            return
        }
        localStorage.setItem('last_game_id', gameInfo.shortId)
    }, [gameInfo])

    const BetChips = useMemo(() => {
        if (!gameInfo) {
            return []
        }
        const betAmounts = gameInfo.betAmounts || [];
        const chips = [];
        betAmounts.forEach((v, i) => {
            chips.push({
                id: i + 1,
                betAmount: v,
                color: BetChipStyles[i]
            })
        })
        return chips;
    }, [gameInfo]);

    const BetChipMap = useMemo(() => {
        if (!BetChips) {
            return [];
        }
        let map = {};
        BetChips.forEach(v => {
            map[v.id] = v;
        })
        return map;
    }, [BetChips]);

    const balance = useMemo(() => {
        return loggedInUser ? loggedInUser.balance : 0
    }, [loggedInUser]);

    const betAvailable = useMemo(() => {
        return !!round && round.betAvailable;
    }, [round]);
    const roundId = useMemo(() => {
        return !!round ? round.roundId : null;
    }, [round]);
    const roundNumber = useMemo(() => {
        return round ? round.roundNumber : null;
    }, [round])
    const winningNumber = useMemo(() => {
        return round ? round.winningNumber : null;
    }, [round])
    const bets = useMemo(() => {
        return round ? round.bets : [];
    }, [round])
    const winnerBets = useMemo(() => {
        return round && round.winnerBets ? round.winnerBets : [];
    }, [round])

    useEffect(() => {
        if(winningNumber != null) {
            setLastWinningNo(winningNumber)
        }
    }, [winningNumber]);

    const totalBet = useMemo(() => {
        let total = 0;
        bets.forEach(b => {
            total += b.betAmount
        })
        return total;
    }, [bets])

    useEffect(() => {
        if (!isEnrolled) {
            navigate('/game/join');
        }
    }, [isEnrolled]);

    useEffect(() => {
        if (gameInfo && !isConnected) {
            setGameInfo(null);
            setRound(null);
            return;
        }

        if (!gameInfo && isConnected && socket) {
            setJoining(true);
            socket.emit(SocketEvents.ClientEvents.JOIN_GAME, {
                joinToken: gameToken,
            })
        }
    }, [socket, isConnected, gameInfo]);


    let handleGameOut = useCallback(() => {
        localStorage.removeItem('last_game_id');
        setGameInfo(null);
        setRound(null);
        clearEnrollment();
    }, []);

    useEffect(() => {
        if (!socket) {
            return
        }

        socket.on(SocketEvents.ServerEvents.JOINED_GAME, (data) => {
            const {gameInfo, activeRound} = data;
            if (gameInfo) {
                setGameInfo(gameInfo);
                setLastWinningNumbers(gameInfo.winningNumbers || []);
            }
            if (activeRound) {
                let roundBets = [];
                if (activeRound.bets) {
                    roundBets = activeRound.bets.map(b => ({
                        ...b.feInfo,
                        id: b._id
                    }))
                }
                setRound({
                    roundId: activeRound.roundId,
                    roundNumber: activeRound.roundNumber,
                    betAvailable: activeRound.betAvailable || false,
                    winningNumber: activeRound.winningNumber || null,
                    status: activeRound.status,
                    bets: roundBets || [],
                });
            }

            setJoining(false);
        });

        socket.on(SocketEvents.ServerEvents.JOIN_GAME_FAILED, ({ message }) => {
            setJoining(false);
            toast(message + ' Çıkılıyor!', {
                pauseOnHover: false,
                onClose: () => {
                    handleGameOut();
                }
            });
        });

        socket.on(SocketEvents.ServerEvents.GAME_CLOSED, ({ gameId }) => {
            setGameInfo((g) => {
                if(g.gameId != gameId) {
                    return g;
                }
                toast("Oyun kapandı! Çıkılıyor!", {
                    pauseOnHover: false,
                    onClose: () => {
                        handleGameOut();
                    }
                });
                return g;
            })
        });

        socket.on(SocketEvents.ServerEvents.ROUND_CREATED, (roundData) => {
            setRound({
                roundId: roundData.roundId,
                roundNumber: roundData.roundNumber,
                status: roundData.status,
                betAvailable: true,
                winningNumber: null,
                bets: roundData.bets || [],
                winnerBets: [],
            });
            toast('YENİ OYUN BAŞLADI')
        });

        socket.on(SocketEvents.ServerEvents.ROUND_BET_CLOSED, (roundData) => {
            const {roundId} = roundData;
            setRound(r => {
                if (!r || r.roundId !== roundId) {
                    return r;
                }
                return {
                    ...r,
                    betAvailable: false
                }
            })
            toast('BAHİSLER KAPANDI')
        });

        socket.on(SocketEvents.ServerEvents.ROUND_WINNING_NUMBER, (roundData) => {
            const {roundId, winningNumber, winnerBets, color} = roundData;
            setRound(r => {
                if (!r || r.roundId !== roundId) {
                    return r;
                }
                if(color) {
                    setLastWinningNumbers(r => {
                        if(r.length === LastWinNoLimit) {
                            r.pop();
                        }
                        return [
                            {
                                winningNumber: winningNumber,
                                color: color,
                            },
                            ...r,
                        ]
                    });
                }

                return {
                    ...r,
                    winningNumber: winningNumber,
                    winnerBets: winnerBets || [],
                }
            })
        });

        socket.on(SocketEvents.ServerEvents.ROUND_CLOSED, (roundData) => {
            const {roundId} = roundData;
            setRound(r => {
                if (!r || r.roundId !== roundId) {
                    return r;
                }
                return null;
            })
        });

        socket.on(SocketEvents.ServerEvents.BET_PLACED, (betData) => {
            const {
                betId,
                betInfo,
            } = betData;

            setRound(r => {
                if (!r || r.roundId !== betData.roundId || !betId || !betInfo) {
                    return r;
                }

                const bet = {...betInfo.feInfo, id: betId};
                const bets = r.bets || [];
                return {
                    ...r,
                    bets: [...bets, bet]
                };
            })
        });

        socket.on(SocketEvents.ServerEvents.BET_CANCELLED, (betData) => {
            const {
                betId,
            } = betData;


            setRound(r => {
                if (!r || r.roundId !== betData.roundId || !betId) {
                    return r;
                }

                if (!r.bets) {
                    return {
                        ...r,
                        bets: []
                    }
                }

                const bets = r.bets;
                const idx = bets.findIndex(b => b.id === betId);
                if (idx < 0) {
                    return r;
                }

                const newBets = [...bets];
                newBets.splice(idx, 1);
                return {
                    ...r,
                    bets: newBets
                }
            });
        });

        socket.on(SocketEvents.ServerEvents.BALANCE_UPDATED, ({balance}) => {
            updateBalance(null, balance)
        });

        socket.on(SocketEvents.ServerEvents.GAME_BET_AMOUNTS_UPDATED, ({gameId, betAmounts}) => {
            setGameInfo(g => {
                if (g.gameId !== gameId) {
                    return g;
                }
                return {
                    ...g,
                    betAmounts
                }
            });
        });

        socket.on(SocketEvents.ServerEvents.BET_WIN, (betData) => {
            const {
                betId,
                betResult,
            } = betData;

            setRound(r => {
                if (!r || r.roundId !== betData.roundId || !betId) {
                    return r;
                }

                // TODO: handle bet lost

                const bets = r.bets || [];
                const idx = bets.findIndex(b => b.id === betId);
                if (idx < 0) {
                    return r;
                }

                const newBets = [...bets];
                newBets.splice(idx, 1);
                return {
                    ...r,
                    bets: newBets
                }
            })
            setTimeout(() => toast(
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'space-between'}}>
                    <h2 style={{fontWeight: "800"}}>KAZANDIN!</h2>
                    <p><span style={{fontWeight: "600", fontSize: "small"}}>Bahis:</span> {betResult.betName}</p>
                    <p><span style={{fontWeight: "600", fontSize: "small"}}>Tutar:</span> {betResult.betAmount}</p>
                    <p><span style={{
                        fontWeight: "600",
                        fontSize: "small"
                    }}>Kazanılan:</span> x{betResult.multiplier} => {betResult.winAmount}</p>
                    <p><span style={{fontWeight: "800", fontSize: "small"}}>Toplam Kazanç: {betResult.totalWin}</span>
                    </p>
                </div>), 200);
        });

        socket.on(SocketEvents.ServerEvents.BET_LOST, (betData) => {
            const {
                betId,
                betResult,
            } = betData;

            setRound(r => {
                if (!r || r.roundId !== betData.roundId || !betId) {
                    return r;
                }

                // TODO: handle bet lost

                const bets = r.bets || [];
                const idx = bets.findIndex(b => b.id === betId);
                if (idx < 0) {
                    return r;
                }

                const newBets = [...bets];
                newBets.splice(idx, 1);
                return {
                    ...r,
                    bets: newBets
                }
            })
            setTimeout(() => toast.warn(
                <div style={{display: "flex", flexDirection: "column", justifyContent: 'space-between'}}>
                    <h2 style={{fontWeight: "800"}}>KAYIP! :(</h2>
                    <p><span style={{fontWeight: "600"}}>Bahis:</span> {betResult.betName}</p>
                    <p><span style={{fontWeight: "600"}}>Tutar:</span> {betResult.betAmount}</p>
                    <p><span style={{fontWeight: "800"}}>Toplam Kazanç: {betResult.totalWin}</span></p>
                </div>), 200);
        });

        socket.on(SocketEvents.ServerEvents.ROUND_TOTAL_WIN, (betData) => {
            const {
                totalWin,
            } = betData;

            setRound(r => {
                if (!r || r.roundId !== betData.roundId) {
                    return r;
                }

                setLastWin(totalWin);
                return r;
            });
        });

        return () => {
            socket.off(SocketEvents.ServerEvents.JOINED_GAME);
            socket.off(SocketEvents.ServerEvents.ROUND_CREATED);
            socket.off(SocketEvents.ServerEvents.ROUND_BET_CLOSED);
            socket.off(SocketEvents.ServerEvents.ROUND_WINNING_NUMBER);
            socket.off(SocketEvents.ServerEvents.ROUND_CLOSED);
            socket.off(SocketEvents.ServerEvents.BET_PLACED);
            socket.off(SocketEvents.ServerEvents.BET_CANCELLED);
            socket.off(SocketEvents.ServerEvents.BALANCE_UPDATED);
            socket.off(SocketEvents.ServerEvents.GAME_BET_AMOUNTS_UPDATED);
            socket.off(SocketEvents.ServerEvents.BET_WIN);
            socket.off(SocketEvents.ServerEvents.BET_LOST);
            socket.off(SocketEvents.ServerEvents.ROUND_TOTAL_WIN);
        }
    }, [socket])

    const handleChangeSelectedChip = useCallback((cbId) => {
        setSelectedChipId(cbId)
        localStorage.setItem(`selected_chip`, cbId)
    }, [])


    const handleBetCancelled = useCallback((betID) => {
        Backend.BetService.cancelBet(betID).then((res) => {
            if (!res.success) {
                toast.error(res.message)
                return;
            }
        });
    }, []);

    const handleCancelAllBets = useCallback(() => {
        Backend.BetService.cancelAllBets(roundId).then((res) => {
            if (!res.success) {
                toast.error(res.message)
                return;
            }
        });
    }, [roundId]);

    const handlePlaceBet = useCallback((betBoxId) => {
        const betBox = BetBoxMap[betBoxId];
        if (!betBox) {
            toast.error('Bahis kutusu sorunu! Sistemsel sorun')
            return;
        }

        const existingBet = bets.find(b => b.betBoxId === betBoxId);
        if (existingBet) {
            handleBetCancelled(existingBet.id)
            return;
        }

        const selectedChip = BetChipMap[selectedChipId];
        if (!selectedChip) {
            toast.error('Bahis çipi sorunu! Sistemsel sorun')
            return
        }

        // if( TotalBalance < selectedChip.betAmount ) {
        //     throw new Error('Bakiye Yetersiz');
        // }

        let addX = 0;
        let addY = 0;
        let gx = 0, gy = 0;
        if (betBox.elemType === 'rect') {
            switch (betBox.rectData.width) {
                case 400:
                    addX = 20;
                    break;
                case 800:
                    addX = 250;
                    break;
                case 1600:
                    addX = 600;
                    break;
            }
            switch (betBox.rectData.height) {
                case 550:
                    addY = 500;
                    break;
                case 400:
                    addY = 400;
                    break;
            }
            gx = betBox.rectData.x + addX;
            gy = betBox.rectData.y + addY;
        } else if (betBox.elemType === 'path') {
            gx = betBox.pathData.center.x;
            gy = betBox.pathData.center.y;
        } else {
            toast.error('Bahis kutu tipi sorunu! Sistemsel sorun')
            return;
        }

        const bet = {
            feKey: `${betBoxId}${++nextBetId}`,
            x: gx,
            y: gy,
            betBoxId: betBoxId,
            chipId: selectedChip.id,
            color: selectedChip.color,
            chipClass: selectedChip.class,
            betAmount: selectedChip.betAmount
        };

        Backend.BetService.placeBet(roundId, {
            amount: selectedChip.betAmount,
            type: betBox.betType,
            selectedNumber: betBox.betData,
            feInfo: bet
        }).then((res) => {
            if (!res.success) {
                toast.error(res.message)
                return;
            }
        });
    }, [roundId, bets, BetChipMap, selectedChipId]);

    const loading = !gameInfo || joining;

    // if(screenOrientation.startsWith('portrait-')) {
    //     return <div>
    //         <img style={{display: "block", margin: "auto", cursor: "zoom-in"}} src="/images/landscape.webp" width="450" height="414" />
    //         <span style={{color: "white", fontWeight:"bold", width: "100%", display: "flex", justifyContent: "center", fontSize: "20pt"}}>
    //             Ekranı yan çeviriniz
    //         </span>
    //     </div>
    // }

    return (
        <div>
            {loading &&
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    zIndex: 999,
                }}
            >
            </div>

            }
            <Flex gap="middle" align="center">
                <SummaryBox
                    balance={balance}
                    totalBet={totalBet}
                    lastWin={lastWin}
                    winningNumber={winningNumber}
                    lastWinningNo={lastWinningNo}
                />
                <Flex gap="middle" align="center" style={{ padding: "5px" }}>
                    <div className="summary-box">
                        <Button onClick={() => setIsLastWinningModalOpen(true)}>Son Kazananlar</Button>
                    </div>
                    <Button
                        disabled={!betAvailable || !bets.length}
                        type="primary"
                        onClick={() => {
                            handleCancelAllBets();
                        }}
                    > Sıfırla
                    </Button>
                    <Popconfirm
                        placement="bottom"
                        title={"Emin misin?"}
                        description={"Oyundan ayrılıyorsun!"}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={handleGameOut}
                    >
                        <Button
                            disabled={!!bets.length}
                            danger
                            type="primary"
                        >
                            Oyundan Çık
                        </Button>
                    </Popconfirm>
                </Flex>
            </Flex>
            <div className="game-container" style={{position: "relative"}}>
                {!betAvailable &&
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        zIndex: 999,
                    }}
                >
                    {
                        !!round && winningNumber == null && <div className="game-roulette-wheel">
                            <RouletteWheel/>
                            <div className="round-bet-closed">
                                <span>BAHİSLER KAPANDI</span>
                            </div>
                        </div>
                    }
                    {
                        !!round && winningNumber !== null && <div className="">
                            {/*<ResultBox winningNumber={winningNumber} />*/}
                        </div>
                    }

                </div>

                }
                <div className="game-left-side">
                    <RouletteTable
                        selectedChipID={selectedChipId}
                        placeBet={handlePlaceBet}
                        cancelBet={handleBetCancelled}
                        bets={bets}
                        betAvailable={betAvailable}
                        winnerBets={winnerBets}
                    />
                </div>
                <div className="game-right-side">
                    {BetChips.map(bc => (
                        <ChipBox
                            id={"bet-chip-" + bc.id}
                            key={"bet-chip-" + bc.id}
                            betAmount={bc.betAmount}
                            color={bc.color}
                            className={`chip-box-${BetChips.length}`}
                            selected={selectedChipId === bc.id}
                            onClick={() => handleChangeSelectedChip(bc.id)}
                        />
                    ))}

                </div>
            </div>

            <Modal title="Son Kazanan Sayılar"
                   className="last-win-modal"
                   open={isLastWinningModalOpen}
                   onOk={()=>{setIsLastWinningModalOpen(false)}}
                   onCancel={() => {setIsLastWinningModalOpen(false)}}
                   okText="Tamam" closable={false}>
                <div className="last-win-container-bg ">
                    <div className="last-win-container">
                        {
                            lastWinningNumbers.map(r =>
                                <div  className={`last-win-box ${r.color}-box`}>
                                    {r.winningNumber}
                                </div>)
                        }
                    </div>
                </div>
            </Modal>
            <Modal title="Game QR Code">
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                    {
                        !!round &&
                        <div style={{width: "30%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <div>Bet Status: {betAvailable ? " Available" : " Closed"}</div>
                            {roundNumber && <div>Round No: {roundNumber}</div>}
                            {winningNumber && <div>{winningNumber}</div>}
                        </div>
                    }
                    {
                        !round && <span>Waiting Round</span>
                    }
                    {
                        serverTime && <span>Server Time: {new Date(serverTime).toISOString()}</span>
                    }
                    {
                        <span>Connection: {isConnected ? " Connected" : " Disconnected"}</span>
                    }

                </div>
            </Modal>
            <ToastContainer
                className={"toast-message"}
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            >
            </ToastContainer>
        </div>
    )
}

export default GamePage;