import React, {useMemo} from "react";
import {Button, Space, Table} from "antd";
import { currencyFormat } from '../../../common/currency';

const emptyFn = () => {};

const getColumns = ({
                        goToUserDetail = emptyFn
                    }) => [
    {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (_, { firstName, lastName }) => `${firstName}    ${lastName}`,
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        render: (_, { balance }) => currencyFormat(balance),
        responsive: ['md']
    },

    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        responsive: ['lg']
    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button 
                    type="primary" 
                    onClick={() => goToUserDetail(record.username)}>Detail</Button>
            </Space>
        ),
    },
];

const UsersTable = ({ loading, data, goToUserDetail = emptyFn }) => {
    const columns = useMemo(() => {
        return getColumns({
            goToUserDetail
        })
    }, [goToUserDetail]);
    return <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        size="middle"
        tableLayout="fixed"
    />
}

export default UsersTable;