import {api, safeCall } from '../api';

export const searchBets = ({ roundId, username, gameShortId, status}) => {
    return safeCall(api.post(`/admin/bet/search`, {
        roundId,
        username,
        gameId: gameShortId,
        status
    }));
}

export const cancelUserAllBet = ({roundId, userId}) => {
    return safeCall(api.delete(`/admin/bet/cancel-all-bets?roundId=${roundId}&userId=${userId}`));
}

export const cancelBet = ({betId}) => {
    return safeCall(api.delete(`/admin/bet/${betId}/cancel-bet`));
}