import {api, safeCall } from '../api';

export const findAllTransactionsByUsername = async (username) => {
    return safeCall(api.get(`/admin/account/${username}/transactions`));
};

export const deposit = async (username, amount) => {
    return safeCall(api.post(`/admin/account/${username}/deposit`, {
        amount
    }));
};

export const withdraw = async (username, amount) => {
    return safeCall(api.post(`/admin/account/${username}/withdraw`, {
        amount
    }));
};
