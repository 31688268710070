import React, {useMemo} from "react";
import {Button, Space, Table} from "antd";
import { currencyFormat } from '../../../common/currency';

const emptyFn = () => {};

const getColumns = ({
                        goToGameDetail = emptyFn,
                        goToGameQr = emptyFn
                    }) => [
    {
        title: 'ID',
        dataIndex: 'shortId',
        key: 'shortId',
    },
    {
        title: 'Name',
        dataIndex: 'gameName',
        key: 'name',
        responsive: ['md']
    },

    {
        title: 'User Count',
        dataIndex: 'userCount',
        key: 'userCount',
        responsive: ['lg']
    },
    {
        title: 'Round Count',
        dataIndex: 'roundCount',
        key: 'roundCount',
        responsive: ['lg']
    },
    {
        title: 'Total Bet',
        key: 'summary',
        dataIndex: 'summary',
        render: (_, { summary }) => currencyFormat(summary.totalBetAmount),
        responsive: ['lg']
    },
    {
        title: 'Total Win',
        key: 'summary',
        dataIndex: 'summary',
        render: (_, { summary }) => currencyFormat(summary.totalWinAmount),
        responsive: ['lg']
    },
    {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        responsive: ['md']
    },
    {
        title: 'Detail',
        key: 'detail',
        render: (_, record) => (
            <Space size="middle">
                <Button type="primary"
                        // disabled={record.status !== "ACTIVE"}
                        onClick={() => goToGameDetail(record.shortId)}>Detail</Button>
            </Space>

        )
    },
    {
        title: 'QR',
        key: 'qr',
        render: (_, record) => (
            <Space size="middle">
                <Button type="primary" disabled={record.status !== "ACTIVE"}
                        onClick={() => goToGameQr(record.shortId)}>QR</Button>
            </Space>

        ),
    },
];

const GameTable = ({ loading, data, goToGameDetail = emptyFn, goToGameQr = emptyFn }) => {
    const columns = useMemo(() => {
        return getColumns({
            goToGameDetail, goToGameQr
        })
    }, [goToGameDetail]);
    return <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowClassName={(record) => {
            return record.status === 'CLOSED' ? 'closed-table-row': 'active-table-row'
        }}
        tableLayout="fixed"
    />
}

export default GameTable;