import React, {useMemo} from "react";
import {Button, Popconfirm, Space, Table} from "antd";
import { currencyFormat } from '../../../common/currency';

const emptyFn = () => {}
const getColumns = ({
                        goToUserDetail = emptyFn,
                        goToUserBetList = emptyFn,
                        cancelAllUserBet = emptyFn
                    }) => [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (_, { user }) => (
            <a onClick={() => goToUserDetail(user.username)}>{user.name}</a>
        ),
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        render: (_, { user }) => (
            <span>{user.username}</span>
        ),
        responsive: ['md']
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        render: (_, { user }) => currencyFormat(user.balance),
        responsive: ['md']
    },
    {
        title: 'Bet Count',
        dataIndex: 'betCount',
        key: 'betCount',
        responsive: ['lg']
    },
    {
        title: 'Active Bet Count',
        dataIndex: 'activeCount',
        key: 'activeCount',
        responsive: ['lg']
    },
    {
        title: 'Bet Amount',
        dataIndex: 'totalBetAmount',
        key: 'totalBetAmount',
        render: (_, { totalBetAmount}) => currencyFormat(totalBetAmount)
    },
    {
        title: 'Win Amount',
        dataIndex: 'totalWinAmount',
        key: 'totalWinAmount',
        render: (_, { totalWinAmount}) => currencyFormat(totalWinAmount)
    },
    {
        title: 'List',
        key: 'list',
        render: (_, { user }) => {
            return <Space size="middle">
                <Button type="primary"
                        onClick={() => goToUserBetList(user.username)}>Detail</Button>
            </Space>
        },
    },
    {
        title: 'Cancel',
        key: 'cancel',
        render: (_, { user }) => {
            return <Space size="middle">
                <Popconfirm
                    placement="bottom"
                    title={"Are you sure?"}
                    description={"All bets will be cancelled!"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => cancelAllUserBet(user._id)}
                >
                    <Button type="primary" danger>Cancel All</Button>
                </Popconfirm>
            </Space>
        },
    },
];

const UserBetSummaryTable = ({ loading, data, goToUserDetail = emptyFn,
                             goToUserBetList=emptyFn, cancelAllUserBet = emptyFn }) => {
    const columns = useMemo(() => {
        return getColumns({
            goToUserDetail,
            goToUserBetList,
            cancelAllUserBet
        })
    }, [goToUserDetail]);
    return <Table
        expandable
        columns={columns}
        dataSource={data}
        loading={loading}
        tableLayout="fixed"
    />
}

export default UserBetSummaryTable;