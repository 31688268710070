import {api, safeCall } from '../api';

export const findAll = async () => {
    return safeCall(api.get(`/admin/game`));
};

export const getGameDetailAdmin = async (gameShortId) => {
    return safeCall(api.get(`/admin/game/${gameShortId}`));
};

export const updateBetAmounts = async (gameShortId, betAmounts = []) => {
    return safeCall(api.patch(`/admin/game/${gameShortId}/bet-amounts`, {
        betAmounts
    }));
};

export const closeGame = async (gameShortId) => {
    return safeCall(api.patch(`/admin/game/${gameShortId}/close-game`));
};

export const createGame = async (gameName) => {
    return safeCall(api.post(`/admin/game`, {
        gameName
    }));
};