const BetChipStyles = [
    '#cbaad7',
    '#A70E13',
    '#005599',
    '#ffe994',
    '#94ffa2',
    '#fa9966',
];

export {
    BetChipStyles
}