export const ClientEvents = {
    DISCONNECT: 'disconnect',

    JOIN_GAME: 'join_game',
};

export const ServerEvents = {
    JOINED_GAME: 'joined_game',
    JOIN_GAME_FAILED: 'join_game_failed',
    BALANCE_UPDATED: 'balance_updated',
    GAME_BET_AMOUNTS_UPDATED: 'game_bet_amounts_updated',
    GAME_CLOSED: 'game_closed',

    ROUND_CREATED: 'round_created',
    ROUND_BET_CLOSED: 'round_bet_closed',
    ROUND_WINNING_NUMBER: 'round_winning_number',
    ROUND_CLOSED: 'round_closed',

    BET_PLACED: 'bet_placed',
    BET_CANCELLED: 'bet_cancelled',
    BET_WIN: 'bet_win',
    BET_LOST: 'bet_lost',
    ROUND_TOTAL_WIN: 'round_total_win',
}
