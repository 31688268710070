import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
const { Backend } = require('../service');

const AuthContext = createContext();

const StoreKeys = {
    JWT_TOKEN: 'jwt_token'
}
export const AuthProvider = ({ children }) => {
    const [authCtxLoaded, setAuthCtxLoaded] = useState(false);

    const [authInProgress, setAuthInProgress] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [authToken, setAuthToken] = useState(null);
    const [loginFail, setLoginFail] = useState('');

    const isAdmin = useMemo(() => {
        return !!loggedInUser && !!loggedInUser.isAdmin
    }, [loggedInUser]);

    const whoami = useCallback((token, successCb, failCb) => {
        Backend.AuthService.whoami(token).then(res => {
            if(!res.success) {
                setAuthInProgress(false);
                setAuthCtxLoaded(true);
                return
            }

            setAuthToken(token);
            localStorage.setItem(StoreKeys.JWT_TOKEN, token);

            setLoggedInUser(res.data);
            setAuthInProgress(false);
            setLoggedIn(true);
            setAuthCtxLoaded(true);
            successCb && successCb();
        }).catch(err => {
            console.error(err);
            setAuthInProgress(false);
            setLoggedInUser(null);
            setLoggedIn(false);
            localStorage.removeItem(StoreKeys.JWT_TOKEN)
            setAuthCtxLoaded(true);
            failCb && failCb();
        })
    }, [])

    useEffect(() => {
        setAuthInProgress(true);

        const storedAuthToken = localStorage.getItem(StoreKeys.JWT_TOKEN) || '';
        if(!storedAuthToken ){
            setAuthInProgress(false);
            setAuthCtxLoaded(true);
            return;
        }

        whoami(storedAuthToken);
    }, []);

    const login = (username, password) => {
        setAuthInProgress(true);
        localStorage.removeItem(StoreKeys.JWT_TOKEN)
        setAuthToken(null);
        setLoggedIn(false);
        setLoggedInUser(null);
        Backend.AuthService.login(username, password).then(res => {
            if(!res.success) {
                setAuthInProgress(false);
                setLoginFail(res.message)
                return
            }

            whoami(res.data.token, () => {
                setLoginFail('')
            });
        }).catch(err => {
            console.error(err);
            setAuthInProgress(false);
            setLoginFail('Başarısız deneme')
        })
    };

    const logout = () => {
        setAuthInProgress(true)
        localStorage.removeItem(StoreKeys.JWT_TOKEN)
        setAuthToken(null)
        setLoggedIn(false)
        setLoggedInUser(null)
        setLoginFail('')
        setAuthInProgress(null)

    };

    const updateBalance = (userId, balance) => {
        setLoggedInUser(u => {
            if(!u) {
                return u;
            }
            return {
                ...u,
                balance
            }
        })
    }

    const fetchBalance = () => {
        Backend.UserService.fetchBalance().then(res => {
            if(!res.success) {
                console.error(res.message);
                return
            }
            if(isNaN(Number(res.data))) {
                return;
            }

            setLoggedInUser((u) => {
                return {
                    ...u,
                    balance: Number(res.data)
                }
            })
        }).catch(err => {
            console.error(err);
        })
    }

    return (
        <AuthContext.Provider value={{
            authCtxLoaded, loginFail, authInProgress,
            isAdmin, loggedIn, loggedInUser, authToken,
            fetchBalance, updateBalance, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthCtx = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthCtx must be used within an AuthProvider');
    }
    return context;
};