export default {
    SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
    NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',

    UNAUTHORIZED: 'UNAUTHORIZED',
    FORBIDDEN: 'FORBIDDEN',

    USERNAME_PASSWORD_WRONG: 'USERNAME_PASSWORD_WRONG',
    USER_NOT_FOUND: 'USER_NOT_FOUND',

    GAME_COULD_NOT_BE_CREATED: 'GAME_COULD_NOT_BE_CREATED',
    GAME_NOT_FOUND: 'GAME_NOT_FOUND',
    ENROLLMENT_FAILED: 'ENROLLMENT_FAILED',
    GAME_CLOSED: 'GAME_CLOSED',
    GAME_HAS_ACTIVE_ROUND: 'GAME_HAS_ACTIVE_ROUND',

    ROUND_NOT_FOUND: 'ROUND_NOT_FOUND',
    ROUND_CLOSED: 'ROUND_CLOSED',

    USER_NOT_ENROLLED_TO_GAME: 'USER_NOT_ENROLLED_TO_GAME',
    BET_NOT_FOUND: 'BET_NOT_FOUND',
    BET_ALREADY_CANCELLED: 'BET_ALREADY_CANCELLED',
    BET_CANNOT_BE_CANCELLED: 'BET_CANNOT_BE_CANCELLED',
    INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
};