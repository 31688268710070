import {api, safeCall } from './api';

export const placeBet = async (roundId, betInfo) => {
    return safeCall(api.post(`/bet/place-bet`, {
        roundId,
        betInfo
    }));
};

export const cancelBet = async (betId) => {
    return safeCall(api.delete(`/bet/${betId}/cancel-bet`));
};

export const cancelAllBets = async (roundId) => {
    return safeCall(api.delete(`/bet/cancel-all-bets?roundId=${roundId}`));
};