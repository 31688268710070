import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import { useGameCtx } from '../../contexts/GameContexts';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAuthCtx} from "../../contexts/AuthContexts";
import { Backend } from '../../service';
import {Alert, Button, Input} from "antd";

const GameJoinPage = ({ onJoin }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { setEnrollment } = useGameCtx();
    const { logout, loggedInUser } = useAuthCtx();

    const [inProgress, setInProgress] = useState(false);
    const [failText, setFailText] = useState('');
    const [gameId, setGameId] = useState(searchParams.get("game_id") || localStorage.getItem('last_game_id') || '');

    const handleJoin = useCallback((forceGameId) => {
        const gId = forceGameId || gameId;

        setInProgress(true);
        if(!gId) {
            if(!!gameId) {
                setFailText("Oyun id girmelisin!")
            }
            setInProgress(false);
            return
        }

        Backend.GameService.enrollGame(gId).then((res) => {
            if(!res.success) {
                setFailText(res.message)
                return;
            }

            const joinToken = res.data.joinToken;
            setEnrollment(joinToken);
            navigate('/game/play');
        }).finally(() => {
            setInProgress(false);
        });
    }, [setEnrollment, gameId, navigate]);

    const handleLogout = useCallback(() => {
        logout();
        navigate('/login');
    }, [logout, navigate]);

    useLayoutEffect(() => {
        const gId = searchParams.get("game_id")
        if(!!gId) {
            handleJoin(gId)
        }
    }, [])

    return (
        <div className="bg-container">
            <div className="form-container">
                <div className="header">
                    <h1>Hoş geldin, {loggedInUser.firstName} {loggedInUser.lastName}!</h1>
                    <h3>Oyuna katılmak için id'yi giriniz.</h3>
                </div>

                <Input.Search
                    placeholder="oyun id"
                    enterButton="Katıl"
                    size="large"
                    loading={inProgress}
                    allowClear
                    value={gameId}
                    onSearch={handleJoin}
                    onChange={(e) => setGameId(e.target.value)}
                    disabled={inProgress}
                />

                {
                    !inProgress && failText && <Alert message={failText} />
                }

                <Button size="large" block onClick={handleLogout} disabled={inProgress} danger type="primary">Çıkış</Button>
            </div>
        </div>
    );
};

export default GameJoinPage;