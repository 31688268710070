import React  from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContexts';
import { GameProvider} from "./contexts/GameContexts";
import { SocketProvider } from './contexts/SocketContext';

// Game
import LoginPage from './pages/Login/Login.page';
import GameJoinPage from './pages/GameJoin/GameJoin.page';
import GamePage from './pages/Game/Game.page';
import QRPage from './pages/QRPage.page';

// Admin
import AdminHome from './pages/Admin/AdminHome.page';
import AdminGameDetail from "./pages/Admin/AdminGameDetail/AdminGameDetail.page";

import ProtectedRoute from './common/ProtectedRoute'
import AdminLayout from "./pages/Admin/AdminLayout/AdminLayout.page";
import AdminRoundDetail from "./pages/Admin/AdminRoundDetail/AdminRoundDetail.page";
import AdminUserDetail from "./pages/Admin/UserDetail/UserDetail.page";
import AdminBetList from "./pages/Admin/AdminBetList/AdminBetList.page";

function App() {
  return (
      <AuthProvider>
          <SocketProvider>
            <GameProvider>
                <Router>
                    <Routes>
                        <Route path="/login" element={<ProtectedRoute element={<LoginPage/>}/>} />
                        <Route path="/game/join" element={<ProtectedRoute element={<GameJoinPage/>}/>} />
                        <Route path="/game/play" element={<ProtectedRoute element={<GamePage/>}/>} />
                        <Route path="/qr-code" element={<QRPage />} />
                        <Route path="/admin/home" element={<ProtectedRoute element={<AdminLayout><AdminHome/></AdminLayout>}/>} />
                        <Route path="/admin/game/:gameShortId" element={<ProtectedRoute element={<AdminLayout><AdminGameDetail/></AdminLayout>}/>} />
                        <Route path="/admin/game/:gameShortId/round/:roundId" element={<ProtectedRoute element={<AdminLayout><AdminRoundDetail/></AdminLayout>}/>} />
                        <Route path="/admin/bets" element={<ProtectedRoute element={<AdminLayout><AdminBetList/></AdminLayout>}/>} />
                        <Route path="/admin/user/:username" element={<ProtectedRoute element={<AdminLayout><AdminUserDetail/></AdminLayout>}/>} />
                        <Route path="*" element={<ProtectedRoute/>} />
                    </Routes>
                </Router>
            </GameProvider>
          </SocketProvider>
      </AuthProvider>
  );
}

export default App;