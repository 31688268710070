import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './rouletteTable.style.css';
import SvgChip from '../SvgChip/SvgChip.component';
import {BetBoxes, BetBoxMap} from '../../constants/betbox.constants'
import { BetChipMap} from '../../constants/betChip.constants'
import {useGameCtx} from "../../contexts/GameContexts";

const handleFail = (failTextTimerRef, setFailText, failMessage) => {
    if(failTextTimerRef.current != null) {
        clearTimeout(failTextTimerRef.current)
    }
    setFailText(failMessage)

    const timeoutId = setTimeout(() => {
        setFailText("")
    }, 3000)
    failTextTimerRef.current = timeoutId;
}

const RouletteTable = ({ containerClass = "", bets = [], placeBet =() => {}, cancelBet=()=>{}, winnerBets = [] })  => {
    const [failText, setFailText] = useState(1);
    const failTextTimerRef = useRef(null);

    const handleBetBoxClick = useCallback((betBoxId) => {
        try {
            placeBet(betBoxId)
        } catch (err) {
            handleFail(failTextTimerRef, setFailText, err.message)
        }
    }, [placeBet]);

    const handleBetClick = useCallback((betID) => {
        try {
            cancelBet(betID);
        } catch (err) {
            handleFail(failTextTimerRef, setFailText, err.message)
        }
    }, [cancelBet]);

    const isWinnerBet = useCallback((type, data) => {
        const isWinner = winnerBets.findIndex(w => w.type === type && w.data === data) >= 0;
        if(isWinner) {
            console.log(type, data)
        }
        return isWinner;
    }, [winnerBets]);

    return (
        <div className={"table " + containerClass}>
            <img src="/images/roulette/roulette-table.jpg" alt="table" width="100%" />
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width="100%" viewBox="0 0 720.000000 360.000000"
                 preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)"
                   fill="transparent" stroke="none" id="container">

                    {BetBoxes.map((betBox) => {
                        switch (betBox.elemType) {
                            case "rect":
                                return <rect
                                    key={"betbox-"+betBox.id}
                                    x={betBox.rectData.x}
                                    y={betBox.rectData.y}
                                    width={betBox.rectData.width}
                                    height={betBox.rectData.height}
                                    className={`${betBox.class} ${isWinnerBet(betBox.betType, betBox.betData) ? 'winner-bet': ''}`}
                                    onClick={() => handleBetBoxClick(betBox.id)}
                                />
                            case "path":
                                return <path
                                    key={betBox.id}
                                    d={betBox.pathData.d}
                                    className={`${betBox.class} ${isWinnerBet(betBox.betType, betBox.betData) ? 'winner-bet': ''}`}
                                    onClick={() => handleBetBoxClick(betBox.id)}
                                />
                        }
                    })}

                    {bets.map(b => (
                        <SvgChip
                            id={"bet-" + b.id}
                            key={"bet-" + b.id}
                            x={b.x}
                            y={b.y}
                            betAmount={b.betAmount}
                            chipClass={b.chipClass}
                            color={b.color}
                            onClick={() => handleBetClick(b.id)}
                        />
                    ))}
                </g>
            </svg>
        </div>
    )
};

export default RouletteTable;