import React from 'react';
import './chip.style.css';


const SvgChip = ({ id, containerClass = "", chipClass = "chip0",color, betAmount = 1, x, y, isScaled= true, onClick })  => {
    return (
       <g transform={`translate(${x},${y}) ${isScaled ? 'scale(2, -2)' : ''}`} className={"bet-chip" + containerClass} id={id} onClick={onClick}>
           <path xmlns="http://www.w3.org/2000/svg" style={{fill: color}} d="
                M87.2,6.9    c-47.6,0-86.4,38.7-86.4,86.4s38.7,86.4,86.4,86.4s86.4-38.7,86.4-86.4S134.9,6.9,87.2,6.9z
                M111.3,18.9    c15.6,5,29.4,15.1,39.2,28.3l-15.1,10.9c-7.4-10.1-18-17.8-29.9-21.6L111.3,18.9z
                M140.2,75.8    l-5.6,1.9  c-1.6-5-4.1-9.6-7.1-13.9l4.7-3.5C135.7,65.1,138.4,70.3,140.2,75.8z
                M87.3,143.1    c5.2,0,10.4-0.8,15.4-2.5l1.9,5.7  c-5.6,1.8-11.4,2.7-17.3,2.7V143.1z
                M87.1,37.4    v5.9c-5.2,0.1-10.4,0.9-15.3,2.5l-1.9-5.7C75.4,38.4,81.2,37.5,87.1,37.4z
                M63,19        l5.8,17.7c-11.8,3.8-22.3,11.5-29.7,21.7L24,47.4C33.6,34.1,47.5,24.1,63,19z
                M39.9,78      c-1.6,4.9-2.4,10.1-2.4,15.3h-4.6h-1.4c0-5.9,0.9-11.7,2.7-17.2L39.9,78z
                M24.1,139.3    c-9.8-13.4-15-29.3-15-45.9h18.6  c0,12.7,4,24.8,11.4,35L24.1,139.3z
                M42.2,126.1    c-3.5-4.7-6.1-10-8-15.5l5.7-1.9c1.6,5,4,9.6,7.1,13.9L42.2,126.1z
                M46.9,64.1    l-4.8-3.5c3.5-4.7,7.6-8.9,12.3-12.3l3.5,4.8C53.7,56.1,49.9,59.8,46.9,64.1z
                M58,133.5      c4.2,3,8.9,5.5,13.9,7.1l-1.8,5.7  c-5.6-1.8-10.8-4.5-15.6-8L58,133.5z
                M87.2,171.4    c-8.2,0-16.3-1.3-24.1-3.8l5.8-17.7c5.9,1.9,12.1,2.9,18.4,2.9  c6.3,0,12.5-1,18.4-2.9l5.8,17.7C103.6,170.1,95.5,171.4,87.2,171.4z
                M102.6,45.9    l1.8-5.7c5.6,1.8,10.8,4.5,15.6,7.9l-3.5,4.8  C112.2,49.9,107.5,47.5,102.6,45.9z
                M120.1,138.3    l-3.5-4.8c4.2-3,8-6.8,11-11l4.8,3.5C128.9,130.7,124.8,134.9,120.1,138.3z"
           />
           <text x="90" y="100" textAnchor={"middle"} dy=".3em" fontSize="60" fontWeight="bold"
                 fill="black">{betAmount}</text>

       </g>
    )
};

export default SvgChip;