const BetBoxes = [
    { id: 10, elemType: "path", class: "bet-box", betData: null, betType: "zero",
        pathData: {
            d:"M1000 2900 c-32 -68 -120 -249 -196 -403 -76 -154 -137 -284 -138 -290 0 -10 210 -445 333 -690 l65 -130 70 2 70 1 3 816 2 816 -76 0 -75 0 -58 -122z",
            center: {
                x: 800,
                y: 2300
            }
        },
    },
    { id: 110, elemType: "rect", rectData: {x: 1220, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 3, betType: "number"  },
    { id: 111, elemType: "rect", rectData: {x: 1620, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 6, betType: "number"  },
    { id: 112, elemType: "rect", rectData: {x: 2020, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 9, betType: "number"  },
    { id: 113, elemType: "rect", rectData: {x: 2380, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 12, betType: "number" },
    { id: 114, elemType: "rect", rectData: {x: 2780, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 15, betType: "number" },
    { id: 115, elemType: "rect", rectData: {x: 3180, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 18, betType: "number" },
    { id: 116, elemType: "rect", rectData: {x: 3580, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 21, betType: "number" },
    { id: 117, elemType: "rect", rectData: {x: 3980, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 24, betType: "number" },
    { id: 118, elemType: "rect", rectData: {x: 4380, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 27, betType: "number" },
    { id: 119, elemType: "rect", rectData: {x: 4780, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 30, betType: "number" },
    { id: 120, elemType: "rect", rectData: {x: 5590, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 36, betType: "number" },
    { id: 121, elemType: "rect", rectData: {x: 5180, y: 2500, width: 400, height: 550}, class: "bet-box", betData: 33, betType: "number" },
    { id: 122, elemType: "rect", rectData: {x: 5980, y: 2500, width: 400, height: 550}, class: "bet-box", betData: null, betType: "line_1" },
    { id: 210, elemType: "rect", rectData: {x: 1220, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 2, betType: "number"},
    { id: 211, elemType: "rect", rectData: {x: 1620, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 5, betType: "number"},
    { id: 212, elemType: "rect", rectData: {x: 2020, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 8, betType: "number"},
    { id: 213, elemType: "rect", rectData: {x: 2380, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 11, betType: "number"},
    { id: 214, elemType: "rect", rectData: {x: 2780, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 14, betType: "number"},
    { id: 215, elemType: "rect", rectData: {x: 3180, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 17, betType: "number"},
    { id: 216, elemType: "rect", rectData: {x: 3580, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 20, betType: "number"},
    { id: 217, elemType: "rect", rectData: {x: 3980, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 23, betType: "number"},
    { id: 218, elemType: "rect", rectData: {x: 4380, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 26, betType: "number"},
    { id: 219, elemType: "rect", rectData: {x: 4780, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 29, betType: "number"},
    { id: 220, elemType: "rect", rectData: {x: 5180, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 32, betType: "number"},
    { id: 221, elemType: "rect", rectData: {x: 5590, y: 1950, width: 400, height: 550}, class: "bet-box", betData: 35, betType: "number"},
    { id: 222, elemType: "rect", rectData: {x: 5980, y: 1950, width: 400, height: 550}, class: "bet-box", betData: null, betType: "line_2"},
    { id: 310, elemType: "rect", rectData: {x: 1220, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 1, betType: "number"},
    { id: 311, elemType: "rect", rectData: {x: 1620, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 4, betType: "number"},
    { id: 312, elemType: "rect", rectData: {x: 2020, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 7, betType: "number"},
    { id: 313, elemType: "rect", rectData: {x: 2380, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 10, betType: "number"},
    { id: 314, elemType: "rect", rectData: {x: 2780, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 13, betType: "number"},
    { id: 315, elemType: "rect", rectData: {x: 3180, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 16, betType: "number"},
    { id: 316, elemType: "rect", rectData: {x: 3580, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 19, betType: "number"},
    { id: 317, elemType: "rect", rectData: {x: 3980, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 22, betType: "number"},
    { id: 318, elemType: "rect", rectData: {x: 4380, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 25, betType: "number"},
    { id: 319, elemType: "rect", rectData: {x: 4780, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 28, betType: "number"},
    { id: 320, elemType: "rect", rectData: {x: 5180, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 31, betType: "number"},
    { id: 321, elemType: "rect", rectData: {x: 5590, y: 1400, width: 400, height: 550}, class: "bet-box", betData: 34, betType: "number"},
    { id: 322, elemType: "rect", rectData: {x: 5980, y: 1400, width: 400, height: 550}, class: "bet-box", betData: null, betType: "line_3"},
    { id: 410, elemType: "rect", rectData: {x: 1220, y: 975, width: 1600, height: 400}, class: "bet-box", betData: null, betType: "group_1_12"},
    { id: 411, elemType: "rect", rectData: {x: 2800, y: 975, width: 1600, height: 400}, class: "bet-box", betData: null, betType: "group_13_24"},
    { id: 412, elemType: "rect", rectData: {x: 4380, y: 975, width: 1600, height: 400}, class: "bet-box", betData: null, betType: "group_25_36"},
    { id: 510, elemType: "rect", rectData: {x: 1220, y: 550, width: 800, height: 400}, class: "bet-box", betData: null, betType: "group_1_18"},
    { id: 511, elemType: "rect", rectData: {x: 2020, y: 550, width: 800, height: 400}, class: "bet-box", betData: null, betType: "group_even"},
    { id: 512, elemType: "rect", rectData: {x: 2800, y: 550, width: 800, height: 400}, class: "bet-box", betData: null, betType: "group_red"},
    { id: 513, elemType: "rect", rectData: {x: 3600, y: 550, width: 800, height: 400}, class: "bet-box", betData: null, betType: "group_black"},
    { id: 514, elemType: "rect", rectData: {x: 4390, y: 550, width: 800, height: 400}, class: "bet-box", betData: null, betType: "group_odd"},
    { id: 515, elemType: "rect", rectData: {x: 5180, y: 550, width: 800, height: 400}, class: "bet-box", betData: null, betType: "group_19_36"},
];
const BetBoxMap = {};
BetBoxes.forEach(b => {
    if(!!BetBoxMap[b.id]) {
        console.error("Duplicated Bet")
    }
    BetBoxMap[b.id] = b;
});


export {
    BetBoxes,
    BetBoxMap
}