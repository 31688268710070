import React, {useMemo} from "react";
import {Button, Space, Table} from "antd";
import { dateFormat } from '../../../common/date';
import { currencyFormat } from '../../../common/currency';

const emptyFn = () => {};

const getColumns = ({
                        goToRoundDetail = emptyFn
                    }) => [
    {
        title: 'Round No',
        dataIndex: 'roundNumber',
        key: 'roundNumber',
    },
    {
        title: 'Total Bet',
        key: 'summary',
        dataIndex: 'summary',
        render: (_, { summary }) => currencyFormat(summary.totalBetAmount),
        responsive: ['md']
    },
    {
        title: 'Total Win',
        key: 'summary',
        dataIndex: 'summary',
        render: (_, { summary }) => currencyFormat(summary.totalWinAmount),
        responsive: ['lg']
    },
    {
        title: 'Winning No',
        key: 'winningNumber',
        dataIndex: 'winningNumber',
        render: (_, { winningNumber }) => (
            winningNumber != null ? winningNumber : '-'
        ),
        responsive: ['lg']
    },
    {
        title: 'CreatedAt',
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: (_, { createdAt }) => dateFormat(createdAt),
    },
    {
        title: 'Bet ClosedAt',
        key: 'betClosedAt',
        dataIndex: 'betClosedAt',
        render: (_, { betClosedAt }) => dateFormat(betClosedAt),
        responsive: ['lg']
    },
    {
        title: 'ClosedAt',
        key: 'finishedAt',
        dataIndex: 'finishedAt',
        render: (_, { finishedAt }) => dateFormat(finishedAt),
        responsive: ['lg']
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        responsive: ['md']
    },
    {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Button type="primary"
                        // disabled={record.status !== "ACTIVE"}
                        onClick={() => goToRoundDetail(record._id)}>Detail</Button>
            </Space>
        ),
    },
];

const RoundsTable = ({ loading, data, goToRoundDetail = emptyFn }) => {
    const columns = useMemo(() => {
        return getColumns({
            goToRoundDetail
        })
    }, [goToRoundDetail]);
    return <Table
        expandable
        columns={columns}
        dataSource={data}
        loading={loading}
        rowClassName={(record) => {
            return record.status === 'CLOSED' ? 'closed-table-row': 'active-table-row'
        }}
        tableLayout="fixed"
    />
}

export default RoundsTable;