import {api, safeCall } from '../api';

export const createUser = (userInfo) => {
    return safeCall(api.post(`/admin/user/create-user`, userInfo));
}

export const findByUsername = (username) => {
    return safeCall(api.get(`/admin/user/${username}`));
}

export const findAll = async () => {
    return safeCall(api.get('/admin/user'));
};
