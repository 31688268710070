import React, {useCallback, useEffect, useState} from 'react';
import { useAuthCtx } from '../../contexts/AuthContexts';
import { useNavigate } from 'react-router-dom';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import {
    Alert,
    Button,
    Form,
    Input,
} from 'antd';

const LoginPage = () => {
    const { login, loginFail, authInProgress,
        loggedIn, isAdmin } = useAuthCtx();
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const handleLogin = useCallback(() => {
        login(username, password);
    }, [username, password, login, navigate]);

    useEffect(() => {
        if(loggedIn && !isAdmin) {
            navigate('/game/join');
        }
        if(loggedIn && isAdmin) {
            navigate('/admin/home');
        }
    }, [loggedIn, isAdmin]);

    return (
        <div className="bg-container">
            <div className="form-container">
                <Form
                    labelCol={{ span: 15 }}
                    wrapperCol={{ span: 25 }}
                    initialValues={{ size: "large" }}
                    size="large"
                    style={{ maxWidth: 600 }}
                    layout="vertical"
                    autoComplete="off"
                >
                    {
                        !authInProgress && loginFail &&
                        <Alert message={loginFail} />

                    }

                    <Form.Item  label="Kullanıcı Adı">
                        <Input
                            disabled={authInProgress}
                            placeholder="kullanıcı adı"
                            value={username}
                            onChange={(e) => setUsername(e.target.value) }
                        />
                    </Form.Item>
                    <Form.Item label="Şifre">
                        <Input.Password
                            disabled={authInProgress}
                            placeholder="şifre"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button block loading={authInProgress} onClick={handleLogin}>Giriş</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginPage;